import { AuthUser } from '../../core/models/AuthUser';
import { AppNotification } from '../../core/models/notification';

export const AuthenticationSliceName = 'authentication';

export enum AccountType {
    User = 1,
    Admin = 2
}

export enum AuthStateEnum {
    Checking,
    Authenticated,
    Unauthenticated
}

export interface AuthenticationState {
    token?: string;
    loggedInUser?: AuthUser;
    isLoading: boolean;
    authState: AuthStateEnum;
    notifications: AppNotification[];
    stripeAccountStatus?: number;
    onboardingUrl?: string;
    showVerifyEmailComponent: boolean,
    showResetPasswordComponent: boolean
}
