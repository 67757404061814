import React, { useEffect, useState } from 'react';
import { AppBar, Button, LinearProgress, List, ListItem, ListItemText, SwipeableDrawer, Toolbar, Typography } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createStyleSheet } from '../utils/theming';
import {useDispatch, useSelector} from 'react-redux';
import { CoreSelectors } from '../state/selectors';
import { Menu } from '@material-ui/icons';
import { ExitToApp } from '@material-ui/icons';
import {AuthenticationActions} from "../../authentication/state/authentication.slice";
import { AccountType } from '../../authentication/state/authentication.state';

const useStyles = createStyleSheet((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row'
    },
    logoToolbar: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#FBFAFD',
        height: 16,
        minHeight: 48,
        alignItems: 'center',
        justifyContent: 'space-evenly'
    },
    additionalLogo: {
        height: 24
    },
    logoSection: {
        textAlign: 'center',
        paddingRight: 20,
        [theme.breakpoints.down('sm')]: {
            flex: 1,
          },
    },
    navigationSection: {
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
          },
    },
    hamburgerMenu: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            display: 'none',
          },
    },
    userInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    navButton: {
        height: 72
    },
    rightPositionedNavButton: {
        height: 72,
        float: "right",
    },
    buttonIcon: {
        marginRight: 5,
    },
    logo: {
        padding: 5,
        display: 'block',
        maxHeight: 45,
        width: 'auto',
        height: 'auto',
    }
}));

interface NavBarProps {
    loggedInUser?: any;
}

const NavBar = ({loggedInUser}: NavBarProps) => {
    const location = useLocation();
    const history = useHistory();
    const styles = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation('navbar');
    const isLoading = useSelector(CoreSelectors.selectLoading);
    const [currentLocation, setCurrentLocation] = useState(location.pathname);

    useEffect(() => {
        setCurrentLocation(location.pathname);
    }, [location, setCurrentLocation]);

    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

    const toggleDrawer = (open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
      ) => {
        if (
            event &&
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }
    
        setIsDrawerOpen(open);
      };

    return (
        <>
            <AppBar color={'primary'} position={'sticky'}>
                <Toolbar className={styles.container}>
                    <div className={styles.logoSection}>
                        <h2>Pre-Clinicaltrials.Org</h2>
                    </div>
                    <div className={styles.navigationSection}>
                        <Button
                            variant={'contained'}
                            color={currentLocation.startsWith('/main') ? 'secondary' : 'primary'}
                            disableElevation
                            size={'large'}
                            onClick={() => history.push('/main')}
                            className={styles.navButton}
                        >
                            <Typography variant={'button'}>
                                {t('label_main')}
                            </Typography>
                        </Button>
                        <Button
                            variant={'contained'}
                            color={currentLocation.startsWith('/studies') ? 'secondary' : 'primary'}
                            disableElevation
                            size={'large'}
                            onClick={() => history.push('/studies')}
                            className={styles.navButton}
                        >
                            <Typography variant={'button'}>
                                {t('label_studies')}
                            </Typography>
                        </Button>
                        { loggedInUser && loggedInUser.name &&
                            <Button
                                variant={'contained'}
                                color={currentLocation.startsWith('/my-studies') ? 'secondary' : 'primary'}
                                disableElevation
                                size={'large'}
                                onClick={() => history.push('/my-studies')}
                                className={styles.navButton}
                            >
                                <Typography variant={'button'}>
                                    {t('label_my_studies')}
                                </Typography>
                            </Button>
                        }
                        <Button
                            variant={'contained'}
                            color={currentLocation.startsWith('/submit') ? 'secondary' : 'primary'}
                            disableElevation
                            size={'large'}
                            onClick={() => history.push('/submit')}
                            className={styles.navButton}
                        >
                            <Typography variant={'button'}>
                                {t('label_submit')}
                            </Typography>
                        </Button>
                        <Button
                            variant={'contained'}
                            color={currentLocation.startsWith('/about') ? 'secondary' : 'primary'}
                            disableElevation
                            size={'large'}
                            onClick={() => history.push('/about')}
                            className={styles.navButton}
                        >
                            <Typography variant={'button'}>
                                {t('label_about')}
                            </Typography>
                        </Button>
                        <Button
                            variant={'contained'}
                            color={currentLocation.startsWith('/contact') ? 'secondary' : 'primary'}
                            disableElevation
                            size={'large'}
                            onClick={() => history.push('/contact')}
                            className={styles.navButton}
                        >
                            <Typography variant={'button'}>
                                {t('label_contact')}
                            </Typography>
                        </Button>
                        {
                            loggedInUser && loggedInUser.accountType === AccountType.Admin && <>
                                <Button
                                    variant={'contained'}
                                    color={currentLocation.startsWith('/catalogue') ? 'secondary' : 'primary'}
                                    disableElevation
                                    size={'large'}
                                    onClick={() => history.push('/catalogue')}
                                    className={styles.navButton}
                                >
                                    <Typography variant={'button'}>
                                        {t('label_configuration')}
                                    </Typography>
                                </Button>
                            </>
                        }
                        
                        {
                            loggedInUser && loggedInUser.name ? <>
                                <Button
                                    variant={'contained'}
                                    color={'primary'}
                                    disableElevation
                                    size={'large'}
                                    onClick={() => dispatch(AuthenticationActions.logout())}
                                    className={styles.rightPositionedNavButton}
                                >
                                    <ExitToApp className={styles.buttonIcon}/>
                                    <Typography variant={'button'}>
                                        {t('label_logout')}
                                    </Typography>
                                </Button>
                            </> :
                                <Button
                                    variant={'contained'}
                                    color={currentLocation.startsWith('/login') ? 'secondary' : 'primary'}
                                    disableElevation
                                    size={'large'}
                                    onClick={() => history.push('/login')}
                                    className={styles.rightPositionedNavButton}
                                >
                                    <Typography variant={'button'}>
                                        {t('label_login')}
                                    </Typography>
                                </Button>
                        }
                    </div>
                    <div className={styles.hamburgerMenu}>
                        <Menu onClick={toggleDrawer(true)}></Menu>
                    </div>
                </Toolbar>
                <Toolbar className={styles.logoToolbar}>
                    <div style={{ paddingRight: 24 }}>
                        <img className={styles.additionalLogo} src="img/repo4eu-logo.png" alt="repotrial"/>
                    </div>
                    <div>
                        <img style={{ height: 32 }} src="img/repotrial-logo.png" alt="repotrial"/>
                    </div>
                </Toolbar>
                {
                    (isLoading) &&
                    <LinearProgress style={{marginTop: -4, zIndex: 100}}/>
                }
                <React.Fragment key={'right'}>
                    
                    <SwipeableDrawer anchor={'right'}
                                     open={isDrawerOpen}
                                     onClose={toggleDrawer(false)}
                                     onOpen={toggleDrawer(false)}
                                     onClick={toggleDrawer(!isDrawerOpen)}
                    >
                    <List>
                        <ListItem button >
                            <ListItemText primary={t('label_main')} onClick={() => history.push('/main')}/>
                        </ListItem>
                        <ListItem button >
                            <ListItemText primary={t('label_studies')} onClick={() => history.push('/studies')}/>
                        </ListItem>
                        { loggedInUser && loggedInUser.name &&
                            <ListItem button>
                                <ListItemText primary={t('label_my_studies')} onClick={() => history.push('/my-studies')}/>
                            </ListItem>
                        }
                        <ListItem button >
                            <ListItemText primary={t('label_submit')} onClick={() => history.push('/submit')}/>
                        </ListItem>
                        <ListItem button >
                            <ListItemText primary={t('label_about')} onClick={() => history.push('/about')}/>
                        </ListItem>
                        <ListItem button >
                            <ListItemText primary={t('label_contact')} onClick={() => history.push('/contact')}/>
                        </ListItem>
                        { loggedInUser && loggedInUser.name &&
                            <ListItem button>
                                <ListItemText primary={t('label_configuration')} onClick={() => history.push('/catalogue')}/>
                            </ListItem>
                        }
                        <ListItem button >
                            {
                                loggedInUser && loggedInUser.name ? <>
                                    <ListItemText primary={t('label_logout')} onClick={() => dispatch(AuthenticationActions.logout())}/>
                                </> :
                                    <ListItemText primary={t('label_login')} onClick={() => history.push('/login')}/>
                            }
                        </ListItem>
                    </List>
                    </SwipeableDrawer>
                </React.Fragment>
            </AppBar>
        </>
    )
}

export default NavBar;
