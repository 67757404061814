import { ConfigSliceName, ConfigState } from './config.state';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConfigThunks } from './config.thunk';
import { NotificationEnum } from '../../core/models/notification.enum';
import { createErrorNotification, createSuccessNotification } from '../../core/models/notification';

const initialState: ConfigState = {
    isLoading: false,
    catalogues: [],
    catalogueEntries: [],
    notifications: []
}

const configStateSlice = createSlice({
    name: ConfigSliceName,
    initialState: initialState,
    reducers: {
        setLoading: (state, {payload}: PayloadAction<boolean>) => {
            return {
                ...state,
                isLoading: payload
            };
        },
        resetCatalogueEntry: (state) => {
            return {
                ...state,
                catalogueEntry: undefined
            }
        },

        dismissNotification: (state) => {
            return {
                ...state,
                notifications: state.notifications.slice(1)
            }
        },
        clearNotifications: (state) => {
            return {
                ...state,
                notifications: []
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(ConfigThunks.getCatalogues.pending, (state) => {
                return {
                    ...state,
                    isLoading: true,
                    catalogues: []
                };
            })
            .addCase(ConfigThunks.getCatalogues.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    catalogues: payload,
                    isLoading: false
                };
            })
            .addCase(ConfigThunks.getCatalogues.rejected, (state) => {
                return {
                    ...state,
                    isLoading: false,
                    notifications: [...state.notifications, createErrorNotification(NotificationEnum.ERROR_RESOURCE_GET)]
                };
            })
            .addCase(ConfigThunks.getCatalogue.pending, (state) => {
                return {
                    ...state,
                    isLoading: true,
                    catalogue: undefined
                };
            })
            .addCase(ConfigThunks.getCatalogue.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    catalogue: payload,
                    isLoading: false
                };
            })
            .addCase(ConfigThunks.getCatalogue.rejected, (state) => {
                return {
                    ...state,
                    isLoading: false,
                    notifications: [...state.notifications, createErrorNotification(NotificationEnum.ERROR_RESOURCE_GET)]
                };
            })
            .addCase(ConfigThunks.updateCatalogue.pending, (state) => {
                return {
                    ...state,
                    isLoading: true
                };
            })
            .addCase(ConfigThunks.updateCatalogue.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    catalogue: payload,
                    isLoading: false,
                    notifications: [...state.notifications, createSuccessNotification(NotificationEnum.SUCCESS_RESOURCE_UPDATE)]
                };
            })
            .addCase(ConfigThunks.updateCatalogue.rejected, (state) => {
                return {
                    ...state,
                    isLoading: false,
                    notifications: [...state.notifications, createErrorNotification(NotificationEnum.ERROR_RESOURCE_UPDATE)]
                };
            })
            .addCase(ConfigThunks.getCatalogueEntries.pending, (state) => {
                return {
                    ...state,
                    isLoading: true,
                    catalogueEntries: []
                };
            })
            .addCase(ConfigThunks.getCatalogueEntries.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    catalogueEntries: payload,
                    isLoading: false
                };
            })
            .addCase(ConfigThunks.getCatalogueEntries.rejected, (state) => {
                return {
                    ...state,
                    isLoading: false,
                    notifications: [...state.notifications, createErrorNotification(NotificationEnum.ERROR_RESOURCE_GET)]
                };
            })
            .addCase(ConfigThunks.getCatalogueEntry.pending, (state) => {
                return {
                    ...state,
                    isLoading: true,
                    catalogueEntry: undefined
                };
            })
            .addCase(ConfigThunks.getCatalogueEntry.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    catalogueEntry: payload,
                    isLoading: false
                };
            })
            .addCase(ConfigThunks.getCatalogueEntry.rejected, (state) => {
                return {
                    ...state,
                    isLoading: false,
                    notifications: [...state.notifications, createErrorNotification(NotificationEnum.ERROR_RESOURCE_GET)]
                };
            })
            .addCase(ConfigThunks.createCatalogueEntry.pending, (state) => {
                return {
                    ...state,
                    isLoading: true
                };
            })
            .addCase(ConfigThunks.createCatalogueEntry.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    catalogueEntry: payload,
                    isLoading: false,
                    notifications: [...state.notifications, createSuccessNotification(NotificationEnum.SUCCESS_RESOURCE_CREATE)]
                };
            })
            .addCase(ConfigThunks.createCatalogueEntry.rejected, (state) => {
                return {
                    ...state,
                    isLoading: false,
                    notifications: [...state.notifications, createErrorNotification(NotificationEnum.ERROR_RESOURCE_CREATE)]
                };
            })
            .addCase(ConfigThunks.updateCatalogueEntry.pending, (state) => {
                return {
                    ...state,
                    isLoading: true
                };
            })
            .addCase(ConfigThunks.updateCatalogueEntry.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    catalogueEntry: payload,
                    isLoading: false,
                    notifications: [...state.notifications, createSuccessNotification(NotificationEnum.SUCCESS_RESOURCE_UPDATE)]
                };
            })
            .addCase(ConfigThunks.updateCatalogueEntry.rejected, (state) => {
                return {
                    ...state,
                    isLoading: false,
                    notifications: [...state.notifications, createErrorNotification(NotificationEnum.ERROR_RESOURCE_UPDATE)]
                };
            })
            .addCase(ConfigThunks.deleteCatalogueEntry.pending, (state) => {
                return {
                    ...state,
                    isLoading: true,
                    catalogueEntry: undefined
                };
            })
            .addCase(ConfigThunks.deleteCatalogueEntry.fulfilled, (state) => {
                return {
                    ...state,
                    catalogueEntry: undefined,
                    isLoading: false,
                    notifications: [...state.notifications, createSuccessNotification(NotificationEnum.SUCCESS_RESOURCE_DELETE)]
                };
            })
            .addCase(ConfigThunks.deleteCatalogueEntry.rejected, (state) => {
                return {
                    ...state,
                    isLoading: false,
                    notifications: [...state.notifications, createErrorNotification(NotificationEnum.ERROR_RESOURCE_DELETE)]
                };
            })
            
    }
});

export const ConfigActions = {
    ...configStateSlice.actions,
    ...ConfigThunks
}

export const ConfigReducer = configStateSlice.reducer;
