import { createStyleSheet } from '../../core/utils/theming';

import { Card, CardActionArea, CardContent, CardMedia, Grid, Paper, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = createStyleSheet((theme) => ({
    root: {
        width: 300,
        height: '100%'
    },
    media: {
        height: 500,
    },
    mobileAppBody:{
        [theme.breakpoints.down('sm')]: {
            margin: 0
          },
    }
}));

const Impressum = () => {

    const { t } = useTranslation('navbar');
    const styles = useStyles();

    return (
        <Paper className={`App-body ${styles.mobileAppBody}`} variant={'outlined'} square>
            <Typography variant={'h3'} color={'primary'}>
                {t('label_impressum')}
            </Typography>

            <Grid container spacing={2} direction={'row'} justifyContent="center">
                <Grid item>
                    <Card className={styles.root}>
                        <CardActionArea>
                            <CardMedia
                                className={styles.media}
                                image="img/harald_schmidt.jpeg"
                                title="Harald H.H.W. Schmidt"
                            />
                            <CardContent>
                                <div >
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Harald H.H.W. Schmidt
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Maastricht University / CARIM & MIAS
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Dept. of Pharmacology, Prof of Pharmacology and Drug Discovery
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        P.O. Box 616, 6200 MD Maastricht
                                    </Typography>
                                </div>


                                <div>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Contact
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Tel.: +31 43 388 1421
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Tel.: (mobile): +31 6 46705510
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Fax: +31 43 388 4149
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        E-Mail: h​.​schmidt​@​​maastricht​university​.​nl
                                    </Typography>
                                </div>


                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item>
                    <Card className={styles.root}>
                        <CardActionArea>
                            <CardMedia
                                className={styles.media}
                                image="img/jan_baumbach.jpeg"
                                title="Jan Baumbach"
                            />
                            <CardContent>
                                <div >
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Jan Baumbach
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Chair of Experimental Bioinformatics
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        TUM School of Life Sciences Weihenstephan
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Technical University of Munich
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Maximus-von-Imhof-Forum 3
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        85354 Freising-Weihenstephan
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Germany
                                    </Typography>
                                </div>
                                <div >
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Contact
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Tel.: +49-8161-71-2136
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        E-Mail: jan.baumbach@wzw.tum.de
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Website: www.baumbachlab.net
                                    </Typography>
                                </div>

                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

            </Grid>
        </Paper>
    )
}



export default Impressum;