import {createStyleSheet} from "../utils/theming";
import {Theme} from "@material-ui/core";

export const authenticationComponentStyles = createStyleSheet(({breakpoints, palette}: Theme) => ({
    root: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: palette.background.default,
        flexDirection: 'column'
    },
    cardContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    card: {
        [breakpoints.up('sm')]: {
            width: 400,
        },
        [breakpoints.up('md')]: {
            width: 450,
        },
        maxWidth: 500,
        height: 750,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: `linear-gradient(0deg, ${palette.primary.main} 0%, ${palette.primary.main} 60%, rgba(255,255,255,1) 60%, rgba(255,255,255,1) 100%)`
    },
    cardContent: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: 12,
        marginBottom: 8,
        width: '90%'
    },
    cardHeader: {
        flex: 1,
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    cardBody: {
        flex: 3,
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    cardTextInput: {
        [breakpoints.up('sm')]: {
            width: 300,
        },
        [breakpoints.up('md')]: {
            width: 350,
        },
    },
}));