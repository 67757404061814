export enum NotificationEnum {
    ERROR_RESOURCE_GET = 'error_resource_get',
    ERROR_RESOURCE_UPDATE = 'error_resource_update',
    ERROR_RESOURCE_CREATE = 'error_resource_create',
    ERROR_RESOURCE_DELETE = 'error_resource_delete',
    ERROR_SIGN_UP_FAILED = 'error_sign_up_failed',
    ERROR_LOGIN_FAILED = 'error_login_failed',
    ERROR_EMAIL_VERIFICATION_FAILED = 'error_email_verification_failed',
    ERROR_FORGOT_PASSWORD_FAILED = 'error_forgot_password_failed',
    ERROR_CAPTCHA = 'error_captcha',
    SUCCESS_RESOURCE_UPDATE = 'success_resource_update',
    SUCCESS_RESOURCE_DELETE = 'success_resource_delete',
    SUCCESS_RESOURCE_CREATE = 'success_resource_create'
}
