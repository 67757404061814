import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {Button, Checkbox, FormControlLabel, Grid, Paper, TextField, Typography} from '@material-ui/core';
import { createStyleSheet } from "../../core/utils/theming";
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect } from 'react';
import { useAppDispatch } from '../../core/state/store';
import { ArrowBack, Save } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { ConfigSelectors } from '../state/config.selectors';
import { ConfigActions } from '../state/config.slice';
import { useHistory, useParams } from 'react-router-dom';
import { Validation, ValidationError } from '../../core/utils/validation';
import Joi from 'joi';
import { CatalogueEntry } from '../../core/models/Common';
import { joiResolver } from '@hookform/resolvers/joi';
import NotificationHandler from '../../core/components/NotificationHandler';

const useStyles = createStyleSheet((theme) => ({
    mobileAppBody: {
        [theme.breakpoints.down('sm')]: {
            margin: 0
        },
    }
}));

const CatalogueEntryPage = () => {
    const styles = useStyles();
    const params = useParams<{ catid?: string, entryid?: string}>();
    const { t } = useTranslation(['config', 'validation']);
    const dispatch = useAppDispatch();
    const history = useHistory();
    const notifications = useSelector(ConfigSelectors.selectNotifications);
    const catalogueEntry = useSelector(ConfigSelectors.selectCatalogueEntry);

    useEffect(() => {
        if (params.entryid) {
            dispatch(ConfigActions.getCatalogueEntry(params.entryid));
        }
    }, [dispatch, params]);

    useEffect(() => {
        return () => {
            dispatch(ConfigActions.clearNotifications());
        }
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(ConfigActions.resetCatalogueEntry());
        } 
    }, [dispatch]);

    const catalogueEntrySchema = Joi.object<CatalogueEntry>({
        label: Joi.string().max(80).required(),
        position: Joi.number().required()
            .custom((val, helpers) => {
                if (!val && +val !== 0) {
                    return val;
                } else if (+val <= 0) {
                    return helpers.error(ValidationError.NUMBER_MIN);
                } else if (+val >= 100) {
                    return helpers.error(ValidationError.NUMBER_MAX);
                }

                return val;
            }),
        isDefault: Joi.boolean(),
        isActive: Joi.boolean()
    })

    const { control, handleSubmit, errors } = useForm<CatalogueEntry>({
        defaultValues: {},
        resolver: joiResolver(catalogueEntrySchema)
    });

    useEffect(() => {
        if (catalogueEntry) {
            control.setValue('label', catalogueEntry?.label);
            control.setValue('position', catalogueEntry?.position);
            control.setValue('isDefault', catalogueEntry?.isDefault);
            control.setValue('isActive', catalogueEntry?.isActive);
        }

    }, [catalogueEntry, control]);

    const handleSave = useCallback((data: CatalogueEntry) => {
        let newData: { [key: string]: any } = { 
            "catalogueId": +params?.catid!
        };
        if (params?.entryid)
        {
            Object.assign(newData, {"catalogueEntryId": +params?.entryid!});
        }

        Object.entries(data).forEach(([key, val]) => (newData[key] = val !== '' ? val : null));
        console.log(newData)
        
        if (params?.entryid) {
            dispatch(ConfigActions.updateCatalogueEntry(newData)).unwrap();
        }
        else {
            dispatch(ConfigActions.createCatalogueEntry(newData)).unwrap()
            .then(
                (catEntry) => {
                    history.push(`/catalogue/${params?.catid}/entry/${catEntry?.catalogueEntryId}`)}
            )
        }
    }, [dispatch, params, history]);

    const onDismissNotification = useCallback(() => {
        dispatch(ConfigActions.dismissNotification());
    }, [dispatch]);


    return (

        <Paper className={`App-body ${styles.mobileAppBody}`} variant={'outlined'} square>
            <Grid container spacing={2} direction={'column'} style={{ height: '100%' }}>
                <Grid item>
                    <Typography variant={'h3'} color={'primary'}>
                        {t('catalogue_entry_title')}
                    </Typography>
                </Grid>
                <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                    <Grid item xs>
                        <h1>1. {t('catalogue_fields')}</h1>
                    </Grid>
                    <Grid item>
                        <Button
                            size={'large'}
                            variant={'contained'}
                            color={'primary'}
                            onClick={handleSubmit(handleSave, (e) => console.log(e))}
                            startIcon={<Save />}
                        >{t('button_save')}</Button>
                    </Grid>
                    <Grid item>
                        <Button
                            size={'large'}
                            variant={'contained'}
                            color={'primary'}
                            onClick={() => history.push(`/catalogue/${params?.catid}`)}
                            startIcon={<ArrowBack />}
                        >{t('button_back')}</Button>
                    </Grid>
                </Grid>
                <Grid item container spacing={2} direction={'column'}>

                    {/* #### label INPUT #### */}
                    <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                        <Grid item sm={6} xs={12}>
                            <Controller
                                defaultValue={''}
                                control={control}
                                name={'label'}
                                render={
                                    ({ onBlur, onChange, value }, { invalid }) =>
                                        <TextField
                                            fullWidth={true}
                                            error={invalid}
                                            type={'label'}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            label={t('label')}
                                            variant={'outlined'}
                                        />
                                }
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <p className={'validation_message'}>
                                {
                                    Validation
                                        .toErrorMessage('label', ValidationError.STRING_EMPTY, errors, t('required', { ns: 'validation' })) ||
                                    Validation
                                        .toErrorMessage('label', ValidationError.STRING_MAX, errors, t('maxLength', {
                                            ns: 'validation',
                                            len: 80
                                        }))
                                }
                            </p>
                        </Grid>
                    </Grid>

                    {/* #### position INPUT #### */}
                    <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                        <Grid item sm={6} xs={12}>
                            <Controller
                                defaultValue={''}
                                control={control}
                                name={'position'}
                                render={
                                    ({ onBlur, onChange, value }, { invalid }) =>
                                        <TextField
                                            fullWidth={true}
                                            error={invalid}
                                            type={'number'}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            label={t('position')}
                                            variant={'outlined'}
                                        />
                                }
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <p className={'validation_message'}>
                                {
                                    Validation
                                        .toErrorMessage('position', ValidationError.NUMBER, errors, t('required', { ns: 'validation' })) ||
                                    Validation
                                        .toErrorMessage('position', ValidationError.NUMBER_MIN, errors, t('largerThan', {
                                            ns: 'validation',
                                            min: 0
                                        })) ||
                                    Validation
                                        .toErrorMessage('position', ValidationError.NUMBER_MAX, errors, t('lowerThan', {
                                            ns: 'validation',
                                            max: 100
                                        }))
                                }
                            </p>

                        </Grid>
                    </Grid>

                    {/* #### isDefault INPUT #### */}
                    <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                        <Grid item sm={6} xs={12}>
                            <Controller
                                defaultValue={false}
                                control={control}
                                name={'isDefault'}
                                render={
                                    ({ onBlur, onChange, value }) =>
                                        <FormControlLabel
                                            onBlur={onBlur}
                                            control={<Checkbox color="primary" checked={value} onChange={(event) => { onChange(event.target.checked) }} />}
                                            label={t('isDefault')}
                                            labelPlacement="end"
                                        />
                                }
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                        </Grid>
                    </Grid>

                    {/* #### isActive INPUT #### */}
                    <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                        <Grid item sm={6} xs={12}>
                            <Controller
                                defaultValue={false}
                                control={control}
                                name={'isActive'}
                                render={
                                    ({ onBlur, onChange, value }) =>
                                        <FormControlLabel
                                            onBlur={onBlur}
                                            control={<Checkbox color="primary" checked={value} onChange={(event) => { onChange(event.target.checked) }} />}
                                            label={t('isActive')}
                                            labelPlacement="end"
                                        />
                                }
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <NotificationHandler onDismiss={onDismissNotification} notification={notifications[0]} />
        </Paper>
    );
}



export default CatalogueEntryPage;