import { Grid, Paper, Typography } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import { createStyleSheet } from "../../core/utils/theming";

const useStyles = createStyleSheet((theme) => ({
    mobileAppBody:{
        [theme.breakpoints.down('sm')]: {
            margin: 0
          },
    }
}));

const About = () => {
    const { t } = useTranslation('navbar');
    const styles = useStyles();

    return (
        <Paper className={`App-body ${styles.mobileAppBody}`} variant={'outlined'} square>

            <Grid container spacing={2} direction={'column'} style={{ height: '100%' }}>
                <Grid item>
                    <Typography variant={'h3'} color={'primary'}>
                        {t('label_about')}
                    </Typography>

                    <h1>Pre-clinical studies</h1>

                    <p>
                    Exploratory pre-clinical studies generate new hypotheses, typically in animal models of disease; confirmatory pre-clinical studies confirm (or reject) these hypothesis and convert them into reproducible knowledge that can then be the basis for further clinical research and development.
                    </p>

                    <h1>Our vision and mission</h1>

                    <p>
                    Pre-clinicalTrials.org provides unprecedented tools to enhance the validity of pre-clinical research, its reproducibility and later success rate with respect to clinical translation.
                    </p>
                    <p>
                    We do so by enabling meta-analyses through providing access to hitherto unpublished and inaccessible data and combining them, if desired, with Big Data of different evidence levels.
                    </p>

                    <h1>History</h1>

                    <p>
                    2016: Pre-ClinicalTrials.org Consortium formed, website initiated
                    </p>

                    <h1>Literature</h1>

                    <ul>
                        <li>Kleinschnitz et al. NOS knockout or inhibition but not disrupting PSD-95-NOS interaction protect against ischemic brain damage. Journal of Cerebral Blood Flow & Metabolism. SAGE Publications; 2016 Jun 28;:0271678X16657094.</li>
                        <li>Kleikers et al. A combined pre-clinical meta-analysis and randomized confirmatory trial approach to improve data validity for therapeutic target validation. Sci Rep. 2014 Dec 31;5:13428–8.</li>
                        <li>Llovera & Liesz. The next step in translational research: lessons learned from the first preclinical randomized controlled trial. J Neurochem. 2016 Mar 10</li>
                        <li>Llovera et al. Results of a preclinical randomized controlled multicentre trial (pRCT): Anti-CD49d treatment for acute brain ischemia. Sci Transl Med. American Association for the Advancement of Science; 2015 Aug 5;7(299):299ra121–1.</li>
                    </ul>

                    <h1>Policies</h1>

                    <p>
                    Membership is regulated in a Memorandum of Understanding (latest version from October 2016). Further regulations are detailed in the Terms & Conditions section.
                    </p>
                    

                </Grid>
            </Grid>
        </Paper>
        )
}



export default About;