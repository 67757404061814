import { ApiResponse } from '../models/ApiResponse';
import { AxiosInstance } from './axios';
import { CatalogueEntry, Compound, Condition, Contact, Target } from '../models/Common';

const PATH = 'Common'

export const CommonApiService = {
    getOutcomes: (): ApiResponse<CatalogueEntry[]> => {
        return AxiosInstance.get(`${PATH}/Outcomes`);
    },

    getProteinTargetings: (): ApiResponse<CatalogueEntry[]> => {
        return AxiosInstance.get(`${PATH}/ProteinTargetings`);
    },

    getSpecies: (): ApiResponse<CatalogueEntry[]> => {
        return AxiosInstance.get(`${PATH}/Species`);
    },

    getCondition: (conditionId: number): ApiResponse<Condition> => {
        return AxiosInstance.get(`${PATH}/Condition/${conditionId}`);
    },

    getConditions: (): ApiResponse<Condition[]> => {
        return AxiosInstance.get(`${PATH}/Conditions`);
    },

    fetchConditions: (keywords: string): ApiResponse<Condition[]> => {
        return AxiosInstance.post(`${PATH}/FetchConditions`, {keywords});
    },

    getTarget: (targetId: number): ApiResponse<Target> => {
        return AxiosInstance.get(`${PATH}/Target/${targetId}`);
    },

    getTargets: (): ApiResponse<Target[]> => {
        return AxiosInstance.get(`${PATH}/Targets`);
    },

    fetchTargets: (keywords: string): ApiResponse<Target[]> => {
        return AxiosInstance.post(`${PATH}/FetchTargets`, {keywords});
    },

    getCompound: (compoundId: number): ApiResponse<Compound> => {
        return AxiosInstance.get(`${PATH}/Compound/${compoundId}`);
    },

    getCompounds: (): ApiResponse<Compound[]> => {
        return AxiosInstance.get(`${PATH}/Compounds`);
    },

    fetchCompounds: (keywords: string): ApiResponse<Compound[]> => {
        return AxiosInstance.post(`${PATH}/FetchCompounds`, {keywords});
    },

    contact: (data: Partial<Contact>) => {
        return AxiosInstance.post(`${PATH}/Contact`, data);
    },

}
