import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthenticationThunks } from './authentication.thunks';
import { AuthenticationSliceName, AuthenticationState, AuthStateEnum } from './authentication.state';
import {createErrorNotification, createSuccessNotification} from '../../core/models/notification';

const initialState: AuthenticationState = {
    notifications: [],
    isLoading: false,
    authState: AuthStateEnum.Checking,
    showVerifyEmailComponent: false,
    showResetPasswordComponent: false,
}

const authenticationSlice = createSlice({
    name: AuthenticationSliceName,
    initialState,
    reducers: {
        setToken: (state, {payload}: PayloadAction<string>) => {
            return {...state, token: payload};
        },
        setLoading: (state, {payload}: PayloadAction<boolean>) => {
            return {...state, isLoading: payload};
        },
        showVerifyEmailComponent: (state, {payload}: PayloadAction<boolean>) => {
            return {...state, showVerifyEmailComponent: payload};
        },
        showResetPasswordComponent: (state, {payload}: PayloadAction<boolean>) => {
            return {...state, showResetPasswordComponent: payload};
        },
        logoutSync: (state) => {
            return {
                ...state,
                token: undefined,
                authState: AuthStateEnum.Unauthenticated,
                loggedInUser: undefined
            }
        },
        dismissNotification: (state) => {
            return {
                ...state,
                notifications: state.notifications.slice(1)
            }
        },
        clearNotifications: (state) => {
            return {
                ...state,
                notifications: []
            }
        }
    },
    extraReducers: (builder => {
        builder
            .addCase(AuthenticationThunks.signUp.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    showVerifyEmailComponent: true,
                }
            })
            .addCase(AuthenticationThunks.signUp.rejected, (state, action) => {
                const payload: any = action?.payload;

                return {
                    ...state,
                    notifications: [...state.notifications, createErrorNotification(payload.notification)]
                }
            })
            .addCase(AuthenticationThunks.authenticate.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    token: payload.jwtToken,
                    authState: AuthStateEnum.Authenticated,
                    loggedInUser: {
                        accountId: payload.accountId,
                        name: payload.name,
                        phone: payload.phone,
                        accountType: payload.accountType,
                        created: payload.created,
                        isVerified: payload.isVerified
                    }
                }
            })
            .addCase(AuthenticationThunks.authenticate.rejected, (state, action) => {
                const payload: any = action?.payload;

                return {
                    ...state,
                    showVerifyEmailComponent: payload.showVerifyEmailComponent ?? false,
                    notifications: [...state.notifications, createErrorNotification(payload.notification)]
                }
            })
            .addCase(AuthenticationThunks.verifyEmail.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    token: payload.jwtToken,
                    authState: AuthStateEnum.Authenticated,
                    loggedInUser: {
                        accountId: payload.accountId,
                        name: payload.name,
                        phone: payload.phone,
                        accountType: payload.accountType,
                        created: payload.created,
                        isVerified: payload.isVerified
                    }
                }
            })
            .addCase(AuthenticationThunks.verifyEmail.rejected, (state, action) => {
                const payload: any = action?.payload;

                return {
                    ...state,
                    notifications: [...state.notifications, createErrorNotification(payload.notification)]
                }
            })
            .addCase(AuthenticationThunks.refreshToken.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    token: payload.jwtToken,
                    authState: AuthStateEnum.Authenticated,
                    loggedInUser: {
                        accountId: payload.accountId,
                        name: payload.name,
                        phone: payload.phone,
                        accountType: payload.accountType,
                        created: payload.created,
                        isVerified: payload.isVerified
                    }
                }
            })
            .addCase(AuthenticationThunks.refreshToken.rejected, (state, {payload}) => {
                return {
                    ...state,
                    token: undefined,
                    authState: AuthStateEnum.Unauthenticated,
                    loggedInUser: undefined
                }
            })
            .addCase(AuthenticationThunks.logout.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    authState: AuthStateEnum.Unauthenticated,
                    token: undefined,
                    loggedInUser: undefined
                }
            })
            .addCase(AuthenticationThunks.logout.rejected, (state, {payload}) => {
                return {
                    ...state,
                    authState: AuthStateEnum.Unauthenticated,
                    token: undefined,
                    loggedInUser: undefined
                }
            })
            .addCase(AuthenticationThunks.forgotPassword.fulfilled, (state, action) => {
                const payload: any = action?.payload;

                return {
                    ...state,
                    showResetPasswordComponent: true,
                    notifications: [...state.notifications, createSuccessNotification(payload.message)]
                }
            })
            .addCase(AuthenticationThunks.forgotPassword.rejected, (state, action) => {
                const payload: any = action?.payload;

                return {
                    ...state,
                    notifications: [...state.notifications, createErrorNotification(payload.notification)]
                }
            })
            .addCase(AuthenticationThunks.resetPassword.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    token: payload.jwtToken,
                    authState: AuthStateEnum.Authenticated,
                    loggedInUser: {
                        accountId: payload.accountId,
                        name: payload.name,
                        phone: payload.phone,
                        accountType: payload.accountType,
                        created: payload.created,
                        isVerified: payload.isVerified
                    }
                }
            })
            .addCase(AuthenticationThunks.resetPassword.rejected, (state, action) => {
                const payload: any = action?.payload;

                return {
                    ...state,
                    notifications: [...state.notifications, createErrorNotification(payload.notification)]
                }
            })
    })
});

export const AuthenticationActions = {
    ...authenticationSlice.actions,
    ...AuthenticationThunks
}

export const AuthenticationReducer = authenticationSlice.reducer;
