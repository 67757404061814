export interface SchemaErrors {
    [key: string]: {
        type: ValidationError,
        message: string
    }
}

export class Validation {
    public static hasError<T extends object>(property: keyof T, error: ValidationError, errors: any): boolean {
        return errors[property]?.type === error;
    }

    public static toErrorMessage<T extends object>(property: keyof T, error: ValidationError, errors: any, errorText: string): string | null {
        return errors[property]?.type === error ? errorText : null;
    }
}

export enum ValidationError {
    STRING_EMPTY = 'string.empty',
    STRING_EMAIL = 'string.email',
    STRING_MAX = 'string.max',
    STRING_PATTERN = 'string.pattern.base',
    NUMBER_MIN = 'number.min',
    NUMBER_MAX = 'number.max',
    NUMBER = 'number.base',
    OBJECT_BASE = 'object.base',
    INVALID_VALUE = 'any.invalid'
}
