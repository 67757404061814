import { ApplicationState } from '../../core/state/store';
import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state: ApplicationState) => state.study;

export const StudySelectors = {
    selectKeywords: createSelector(selectSelf, state => state.keywords),
    selectStudies: createSelector(selectSelf, state => state.studies),
    selectIsLoading: createSelector(selectSelf, state => state.isLoading),
    selectNotifications: createSelector(selectSelf, state => state.notifications),
    selectMyStudies: createSelector(selectSelf, state => state.myStudies),
    selectStudy: createSelector(selectSelf, state => state.study),
}
