import { StudySliceName, StudyState } from './study.state';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StudyThunks } from './study.thunk';
import { NotificationEnum } from '../../core/models/notification.enum';
import { createErrorNotification, createSuccessNotification } from '../../core/models/notification';

const initialState: StudyState = {
    isLoading: false,
    keywords: "",
    studies: [],
    myStudies: [],
    notifications: [],
}

const studyStateSlice = createSlice({
    name: StudySliceName,
    initialState: initialState,
    reducers: {
        setLoading: (state, {payload}: PayloadAction<boolean>) => {
            return {
                ...state,
                isLoading: payload
            };
        },
        setKeyword: (state, {payload}: PayloadAction<string>) => {
            return {
                ...state,
                keywords: payload
            };
        },
        dismissNotification: (state) => {
            return {
                ...state,
                notifications: state.notifications.slice(1)
            }
        },
        clearNotifications: (state) => {
            return {
                ...state,
                notifications: []
            }
        },
        clearStudy: (state) => {
            return {
                ...state,
                study: null,
            }
        },
    },
    extraReducers: builder => {
        builder
            .addCase(StudyThunks.fetch.pending, (state) => {
                return {
                    ...state,
                    isLoading: true,
                    studies: []
                };
            })
            .addCase(StudyThunks.fetch.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    studies: payload,
                    isLoading: false
                };
            })
            .addCase(StudyThunks.fetch.rejected, (state) => {
                return {
                    ...state,
                    isLoading: false,
                    notifications: [...state.notifications, createErrorNotification(NotificationEnum.ERROR_RESOURCE_GET)]
                };
            })
            .addCase(StudyThunks.create.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    isLoading: false,
                    notifications: [...state.notifications, createSuccessNotification(NotificationEnum.SUCCESS_RESOURCE_CREATE)]
                };
            })
            .addCase(StudyThunks.create.rejected, (state) => {
                return {
                    ...state,
                    isLoading: false,
                    notifications: [...state.notifications, createErrorNotification(NotificationEnum.ERROR_RESOURCE_CREATE)]
                };
            })
            .addCase(StudyThunks.fetchMyStudies.pending, (state) => {
                return {
                    ...state,
                    isLoading: true,
                    myStudies: []
                };
            })
            .addCase(StudyThunks.fetchMyStudies.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    myStudies: payload,
                    isLoading: false,
                };
            })
            .addCase(StudyThunks.fetchMyStudies.rejected, (state) => {
                return {
                    ...state,
                    isLoading: false,
                };
            })
            .addCase(StudyThunks.getStudy.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    study: payload,
                    isLoading: false,
                };
            })
            .addCase(StudyThunks.getStudy.rejected, (state) => {
                return {
                    ...state,
                    isLoading: false,
                    notifications: [...state.notifications, createErrorNotification(NotificationEnum.ERROR_RESOURCE_GET)]
                };
            })
            .addCase(StudyThunks.update.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    isLoading: false,
                    notifications: [...state.notifications, createSuccessNotification(NotificationEnum.SUCCESS_RESOURCE_UPDATE)]
                };
            })
            .addCase(StudyThunks.update.rejected, (state) => {
                return {
                    ...state,
                    isLoading: false,
                    notifications: [...state.notifications, createErrorNotification(NotificationEnum.ERROR_RESOURCE_UPDATE)]
                };
            })
    }
});

export const StudyActions = {
    ...studyStateSlice.actions,
    ...StudyThunks
}

export const StudyReducer = studyStateSlice.reducer;
