import { CommonSliceName, CommonState } from './common.state';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonThunks } from './common.thunk';
import { NotificationEnum } from '../../core/models/notification.enum';
import { createErrorNotification, createSuccessNotification } from '../../core/models/notification';

const initialState: CommonState = {
    isLoading: false,
    outcome: [],
    proteinTargeting: [],
    species: [],
    condition: null,
    conditions: [],
    target: null,
    targets: [],
    compound: null,
    compounds: [],
    notifications: []
}

const commonStateSlice = createSlice({
    name: CommonSliceName,
    initialState: initialState,
    reducers: {
        setLoading: (state, {payload}: PayloadAction<boolean>) => {
            return {
                ...state,
                isLoading: payload
            };
        },
        pushErrorNotification: (state, {payload} : PayloadAction<string>) => {
            return {
                ...state,
                notifications: [...state.notifications, createErrorNotification(payload)]
            }
        },
        dismissNotification: (state) => {
            return {
                ...state,
                notifications: state.notifications.slice(1)
            }
        },
        clearNotifications: (state) => {
            return {
                ...state,
                notifications: []
            }
        },
        clearConditions: (state) => {
            return {
                ...state,
                condition: null,
                conditions: [],
            }
        },
        clearTargets: (state) => {
            return {
                ...state,
                target: null,
                targets: [],
            }
        },
        clearCompounds: (state) => {
            return {
                ...state,
                compound: null,
                compounds: [],
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(CommonThunks.outcomes.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    outcome: payload,
                    isLoading: false
                };
            })
            .addCase(CommonThunks.proteinTargetings.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    proteinTargeting: payload,
                    isLoading: false
                };
            })
            .addCase(CommonThunks.species.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    species: payload,
                    isLoading: false
                };
            })
            .addCase(CommonThunks.condition.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    condition: payload,
                    isLoading: false
                };
            })
            .addCase(CommonThunks.conditions.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    conditions: payload,
                    isLoading: false
                };
            })
            .addCase(CommonThunks.fetchConditions.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    conditions: payload,
                    isLoading: false
                };
            })
            .addCase(CommonThunks.target.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    target: payload,
                    isLoading: false
                };
            })
            .addCase(CommonThunks.targets.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    targets: payload,
                    isLoading: false
                };
            })
            .addCase(CommonThunks.fetchTargets.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    targets: payload,
                    isLoading: false
                };
            })
            .addCase(CommonThunks.compound.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    compound: payload,
                    isLoading: false
                };
            })
            .addCase(CommonThunks.compounds.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    compounds: payload,
                    isLoading: false
                };
            })
            .addCase(CommonThunks.fetchCompounds.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    compounds: payload,
                    isLoading: false
                };
            })
            .addCase(CommonThunks.contact.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    isLoading: false,
                    notifications: [...state.notifications, createSuccessNotification(NotificationEnum.SUCCESS_RESOURCE_CREATE)]
                };
            })
            .addCase(CommonThunks.contact.rejected, (state) => {
                return {
                    ...state,
                    isLoading: false,
                    notifications: [...state.notifications, createErrorNotification(NotificationEnum.ERROR_RESOURCE_CREATE)]
                };
            })
    }
});

export const CommonActions = {
    ...commonStateSlice.actions,
    ...CommonThunks
}

export const CommonReducer = commonStateSlice.reducer;
