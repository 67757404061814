import { useHistory } from 'react-router-dom';
import { createStyleSheet } from '../utils/theming';
import { useTranslation } from 'react-i18next';

const useStyles = createStyleSheet(() => ({
    main_footer: {
        backgroundColor: "#0C3C60",
        color: "white",
        position: 'relative',
        textAlign: "center",
        bottom: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        gap: '30px',
        padding: '10px 0px'
    },
    footer_item: {
        cursor: 'pointer'
    }
}));

const Footer = () => {


    const styles = useStyles();
    const history = useHistory();
    const { t } = useTranslation('navbar');

    return (


        <div className={styles.main_footer}>
            <div className={styles.footer_item} onClick={() => history.push('/terms')}>{t('label_terms')}</div>
            <div className={styles.footer_item} onClick={() => history.push('/disclaimer')}>{t('label_disclaimer')}</div>
            <div className={styles.footer_item} onClick={() => history.push('/impressum')}>{t('label_impressum')}</div>
        </div>
    )
}



export default Footer;