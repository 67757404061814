import { Grid, Paper, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { createStyleSheet } from "../../core/utils/theming";


const useStyles = createStyleSheet((theme) => ({
    mobileAppBody:{
        [theme.breakpoints.down('sm')]: {
            margin: 0
          },
    }
}));

const Disclaimer = () => {
    const {t} = useTranslation('navbar');
    const styles = useStyles();

    return (
        <Paper className={`App-body ${styles.mobileAppBody}`} variant={'outlined'} square>
            <Grid container spacing={2} direction={'column'} style={{ height: '100%' }}>
                <Grid item>
                    <Typography variant={'h3'} color={'primary'}>
                        {t('label_disclaimer')}
                    </Typography>

                    <h1>Endorsement Disclaimer — Links to Other Sites</h1>

                    <p>
                    Our Web site has links to other resources and organisations. You are subject to that site's privacy policy when you leave our site. We aim to comply with accessibility regulations, but we cannot be responsible for compliance (accessibility) on other Web sites. Reference in this Web site to any specific commercial products, process, service, manufacturer, or company does not constitute its endorsement or recommendation by the Pre-ClinicalTrials.org Consortium. We are not responsible for the contents of any "off-site" Web page referenced from this server.
                    </p>

                    <h1>Endorsement Disclaimer — Pop-Up Advertisements</h1>

                    <p>
                    When visiting our Web site, your Web browser may produce pop-up advertisements. These advertisements were most likely produced by other Web sites you visited or by third party software installed on your computer. The Pre-ClinicalTrials.org Consortium does not endorse or recommend products or services for which you may view a pop-up advertisement on your computer screen while visiting our site.
                    </p>

                    <h1>Information Disclaimer</h1>

                    <p>
                    The information provided using this Web site is only intended to be general summary information to the public, and upon registration and approval as a member, for research purposes. It is not intended to take the place of peer-reviewed research.
                    </p>

                    <p>
                    It is not the Pre-ClinicalTrials.org Consortium’s intention to provide specific advice to users, instead we provide users with information to help them better interpret their research data. We urge users to consult with a qualified statistician or epidemiologist to conduct meta-analyses. Press releases and other materials meant for public use will not be posted to the Pre-ClinicalTrials.org Web site unless they were developed by or for the Pre-ClinicalTrials.org Website and this initiative itself.
                    </p>

                    <h1>Intrusion Detection</h1>

                    <p>
                    This site is maintained by the Pre-ClinicalTrials.org Consortium. It is protected by various provisions. Violations are subject to criminal prosecution.
                    </p>

                    <p>
                    For site security purposes and to ensure that this service remains available to all users, we employ software programs to monitor traffic to identify unauthorized attempts to upload or change information, or otherwise cause damage. In the event of authorized law enforcement investigations, and pursuant to any required legal process, information from these sources may be used to help identify an individual.
                    </p>
                    

                </Grid>
            </Grid>
        </Paper>
        )
}

export default Disclaimer;