import { createAsyncThunk } from '@reduxjs/toolkit';
import { ConfigSliceName } from './config.state';
import { ConfigApiService } from '../../core/services/config.api.service';
import { Catalogue, CatalogueEntry } from '../../core/models/Common';

export const ConfigThunks = {

    getCatalogues: createAsyncThunk<Catalogue[], void>(`${ConfigSliceName}/GetCatalogues`,
        async (_, {rejectWithValue}) => {
            try{
            const res = await ConfigApiService.getCatalogues();
            return res.data.result;
        }
        catch {
            return rejectWithValue({});
        }
        }),
    getCatalogue: createAsyncThunk<Catalogue, string>(`${ConfigSliceName}/GetCatalogue`,
        async (payload, {rejectWithValue}) => {
            try{
            const res = await ConfigApiService.getCatalogue(payload);
            return res.data.result;
        }
        catch {
            return rejectWithValue({});
        }
        }),
    updateCatalogue: createAsyncThunk<Catalogue, Partial<Catalogue>>(`${ConfigSliceName}/UpdateCatalogue`,
        async (payload, {rejectWithValue}) => {
            try{
            const res = await ConfigApiService.updateCatalogue(payload.catalogueId!, payload);
            return res.data.result;
        }
        catch {
            return rejectWithValue({});
        }
        }),
    getCatalogueEntries: createAsyncThunk<CatalogueEntry[], string>(`${ConfigSliceName}/GetCatalogueEntries`,
        async (payload, {rejectWithValue}) => {
            try{
            const res = await ConfigApiService.getCatalogueEntries(payload);
            return res.data.result;
        }
        catch {
            return rejectWithValue({});
        }
        }),
    getCatalogueEntry: createAsyncThunk<CatalogueEntry, string>(`${ConfigSliceName}/GetCatalogueEntry`,
        async (payload, {rejectWithValue}) => {
            try{
            const res = await ConfigApiService.getCatalogueEntry(payload);
            return res.data.result;
        }
        catch {
            return rejectWithValue({});
        }
        }),
    createCatalogueEntry: createAsyncThunk<CatalogueEntry, Partial<CatalogueEntry>>(`${ConfigSliceName}/CreateCatalogueEntry`,
        async (payload, {rejectWithValue}) => {
            try{
                const res = await ConfigApiService.createCatalogueEntry(payload);
                return res.data.result;
            }
            catch {
                return rejectWithValue({});
            }

            
        }),
    updateCatalogueEntry: createAsyncThunk<CatalogueEntry, Partial<CatalogueEntry>>(`${ConfigSliceName}/UpdateCatalogueEntry`,
        async (payload, {rejectWithValue}) => {
            try{
            const res = await ConfigApiService.updateCatalogueEntry(payload.catalogueEntryId!, payload);
            return res.data.result;
        }
        catch {
            return rejectWithValue({});
        }
        }),
    deleteCatalogueEntry: createAsyncThunk<void, number>(`${ConfigSliceName}/DeleteCatalogueEntry`,
        async (payload, {rejectWithValue}) => {
            try{
            const res = await ConfigApiService.deleteCatalogueEntry(payload);
            return res.data.result;
        }
        catch {
            return rejectWithValue({});
        }
        }),
}
