import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Button, Checkbox, FormControlLabel, Grid, Paper, TextField, Typography} from '@material-ui/core';
import { createStyleSheet } from "../../core/utils/theming";
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect } from 'react';
import { useAppDispatch } from '../../core/state/store';
import {AddCircle, ArrowBack, Refresh, Save} from '@material-ui/icons';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useSelector } from 'react-redux';
import { ConfigSelectors } from '../state/config.selectors';
import { ConfigActions } from '../state/config.slice';
import { useHistory, useParams } from 'react-router-dom';
import { Validation, ValidationError } from '../../core/utils/validation';
import Joi from 'joi';
import { Catalogue } from '../../core/models/Common';
import { joiResolver } from '@hookform/resolvers/joi';
import NotificationHandler from '../../core/components/NotificationHandler';

const useStyles = createStyleSheet((theme) => ({
    mobileAppBody: {
        [theme.breakpoints.down('sm')]: {
            margin: 0
        },
    },
    actionButton: {
        marginRight: 5
    }
}));

const CatalogueDetails = () => {
    const styles = useStyles();
    const params = useParams<{ catid: string }>();
    const { t } = useTranslation(['config', 'validation']);
    const dispatch = useAppDispatch();
    const history = useHistory();
    const catalogue = useSelector(ConfigSelectors.selectCatalogue);
    const catalogueEntries = useSelector(ConfigSelectors.selectCatalogueEntries);
    const notifications = useSelector(ConfigSelectors.selectNotifications);

    useEffect(() => {
        dispatch(ConfigActions.getCatalogue(params.catid));
    }, [dispatch, params]);

    const onRefreshCatalogueEntries = useCallback(() => {
        dispatch(ConfigActions.getCatalogueEntries(params.catid));
    }, [dispatch, params])

    const catalogueSchema = Joi.object<Catalogue>({
        label: Joi.string().max(80).required(),
        isRequired: Joi.boolean()
    })

    const { control, handleSubmit, errors } = useForm<Catalogue>({
        defaultValues: {},
        resolver: joiResolver(catalogueSchema)
    });

    useEffect(() => {
        if (catalogue) {
            control.setValue('label', catalogue?.label);
            control.setValue('isRequired', catalogue?.isRequired);
        }
        dispatch(ConfigActions.getCatalogueEntries(params.catid));
    }, [dispatch, catalogue, control, params]);

    const handleSave = useCallback((data: Catalogue) => {
        let newData: { [key: string]: any } = {"catalogueId" : catalogue?.catalogueId};
        Object.entries(data).forEach(([key, val]) => (newData[key] = val !== '' ? val : null));

        dispatch(ConfigActions.updateCatalogue(newData)).unwrap();
    }, [dispatch, catalogue]);

    const handleDelete = useCallback((id: number) => {
        if (window.confirm(t('confirm_catalogue_entry_deletion'))) {
            dispatch(ConfigActions.deleteCatalogueEntry(id)).unwrap()
            .then(() => {
                dispatch(ConfigActions.getCatalogueEntries(params.catid));
            });
        }
    }, [dispatch, params, t]);

    const onDismissNotification = useCallback(() => {
        dispatch(ConfigActions.dismissNotification());
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(ConfigActions.clearNotifications());
        }
    }, [dispatch]);

    const gridActionCellRenderer = (props: any) => {
        return (
            <>
                <button className={styles.actionButton} onClick={() => history.push(`/catalogue/${params.catid}/entry/${props.value}`)}>{t('button_edit')}</button>
                <button onClick={() => {handleDelete(props.value);}}>{t('button_delete')}</button>
            </>
        );
    };

    return (

        <Paper className={`App-body ${styles.mobileAppBody}`} variant={'outlined'} square>
            <Grid container spacing={2} direction={'column'} style={{ height: '100%' }}>
                <Grid item>
                    <Typography variant={'h3'} color={'primary'}>
                        {t('catalogue_details_title')}
                    </Typography>
                </Grid>
                <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                    <Grid item xs>
                        <h1>1. {t('catalogue_fields')}</h1>
                    </Grid>
                    <Grid item>
                        <Button
                            size={'large'}
                            variant={'contained'}
                            color={'primary'}
                            onClick={handleSubmit(handleSave, (e) => console.log(e))}
                            startIcon={<Save />}
                        >{t('button_save')}</Button>
                    </Grid>
                    <Grid item>
                        <Button
                            size={'large'}
                            variant={'contained'}
                            color={'primary'}
                            onClick={() => { history.push('/catalogue'); }}
                            startIcon={<ArrowBack />}
                        >{t('button_back')}</Button>
                    </Grid>
                </Grid>
                <Grid item container spacing={2} direction={'column'}>

                    {/* #### label INPUT #### */}
                    <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                        <Grid item sm={6} xs={12}>
                            <Controller
                                defaultValue={''}
                                control={control}
                                name={'label'}
                                render={
                                    ({ onBlur, onChange, value }, { invalid }) =>
                                        <TextField
                                            fullWidth={true}
                                            error={invalid}
                                            type={'label'}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            label={t('label')}
                                            variant={'outlined'}
                                        />
                                }
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <p className={'validation_message'}>
                                {Validation
                                    .toErrorMessage('label', ValidationError.STRING_EMPTY, errors, t('required', { ns: 'validation' })) ||
                                    Validation
                                        .toErrorMessage('label', ValidationError.STRING_MAX, errors, t('maxLength', {
                                            ns: 'validation',
                                            len: 80
                                        }))
                                }
                            </p>
                        </Grid>
                    </Grid>

                    {/* #### IsRequired INPUT #### */}
                    <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                        <Grid item sm={6} xs={12}>
                            <Controller
                                defaultValue={false}
                                control={control}
                                name={'isRequired'}
                                render={
                                    ({ onBlur, onChange, value }) =>
                                        <FormControlLabel
                                            onBlur={onBlur}
                                            control={<Checkbox color="primary" checked={value} onChange={(event) => { onChange(event.target.checked) }} />}
                                            label={t('isRequired')}
                                            labelPlacement="end"
                                        />
                                }
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                    <Grid item xs>
                        <h1>2. {t('catalogue_entries')}</h1>
                    </Grid>
                    <Grid item>
                        <Button
                            size={'large'}
                            variant={'contained'}
                            color={'primary'}
                            onClick={() => { history.push(`/catalogue/${params.catid}/newentry`); }}
                            startIcon={<AddCircle />}
                        >{t('button_create')}</Button>
                    </Grid>
                    <Grid item>
                        <Button
                            size={'large'}
                            variant={'contained'}
                            color={'primary'}
                            onClick={onRefreshCatalogueEntries}
                            startIcon={<Refresh />}
                        >{t('button_refresh')}</Button>
                    </Grid>
                </Grid>

                <Grid xs item className="ag-theme-alpine" style={{ width: '100%' }}>
                    <AgGridReact rowData={catalogueEntries} frameworkComponents={{
                        btnCellRenderer: gridActionCellRenderer,
                    }}>
                        <AgGridColumn headerName={t('actions')} field="catalogueEntryId" headerTooltip={t('actions')} resizable cellRenderer='btnCellRenderer' />
                        <AgGridColumn headerName={t('label')} field="label" filter="agTextColumnFilter" sortable headerTooltip={t('label')} resizable tooltipField="label" />
                        <AgGridColumn headerName={t('position')} field="position" filter="agNumberColumnFilter" sortable headerTooltip={t('position')} resizable tooltipField="position" />
                        <AgGridColumn headerName={t('isDefault')} field="isDefault" filter="agTextColumnFilter" sortable headerTooltip={t('isDefault')} resizable tooltipField="isDefault" />
                        <AgGridColumn headerName={t('isActive')} field="isActive" filter="agTextColumnFilter" sortable headerTooltip={t('isActive')} resizable tooltipField="isActive" />
                    </AgGridReact>
                </Grid>
            </Grid>
            <NotificationHandler onDismiss={onDismissNotification} notification={notifications[0]} />
        </Paper>
    );
}



export default CatalogueDetails;