import { ApplicationState } from '../../core/state/store';
import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state: ApplicationState) => state.authentication;

export const AuthenticationSelectors = {
    selectLoggedInUser: createSelector(selectSelf, state => state.loggedInUser),
    selectLoading: createSelector(selectSelf, state => state.isLoading),
    selectToken: createSelector(selectSelf, state => state.token),
    selectAuthState: createSelector(selectSelf, state => state.authState),
    selectNotifications: createSelector(selectSelf, state => state.notifications),
    selectShowVerifyEmailComponent: createSelector(selectSelf, state => state.showVerifyEmailComponent),
    selectShowResetPasswordComponent: createSelector(selectSelf, state => state.showResetPasswordComponent),
}
