import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { StudyState } from '../../study/state/study.state';
import { StudyReducer } from '../../study/state/study.slice';
import { CommonState } from '../../common/state/common.state';
import { CommonReducer } from '../../common/state/common.slice';
import { useDispatch } from 'react-redux';
import { AuthenticationState } from '../../authentication/state/authentication.state';
import { AuthenticationReducer } from '../../authentication/state/authentication.slice';
import { ConfigState } from '../../config/state/config.state';
import { ConfigReducer } from '../../config/state/config.slice';

export interface ApplicationState {
    study: StudyState;
    common: CommonState;
    authentication: AuthenticationState;
    config: ConfigState;
}

const ApplicationReducer = combineReducers<ApplicationState>({
    study: StudyReducer,
    common: CommonReducer,
    authentication: AuthenticationReducer,
    config: ConfigReducer,
});

export const ApplicationStore =  configureStore<ApplicationState>({
    reducer: ApplicationReducer,
    devTools: true
});

export const useAppDispatch = () => useDispatch<typeof ApplicationStore.dispatch>();

