import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    AuthenticateWebRequestBody,
    AuthenticateWebResponseBody,
    ForgotPasswordWebRequestBody,
    ResetPasswordWebRequestBody,
    SignUpWebRequestBody,
    VerifyEmailWebRequestBody
} from '../../core/models/AuthenticationApi.model';
import { AuthenticationApiService } from '../../core/services/authentication.api.service';
import {AuthenticationSliceName} from './authentication.state';
import { AuthenticationActions } from './authentication.slice';
import axios from "axios";
import {NotificationEnum} from "../../core/models/notification.enum";
import {ApiResponseBody} from "../../core/models/ApiResponse";

export const AuthenticationThunks = {
    signUp: createAsyncThunk<AuthenticateWebResponseBody, SignUpWebRequestBody>(`${AuthenticationSliceName}/signUp`,
        async (payload, {dispatch, rejectWithValue}) => {
            dispatch(AuthenticationActions.setLoading(true));
            try {
                const res = await AuthenticationApiService.signUp(payload);
                return res.data.result;
            } catch (error) {
                if (axios.isAxiosError(error) && error.response?.status === 403) {
                    return rejectWithValue({notification: error.response?.data?.Message});
                }
                return rejectWithValue({notification: NotificationEnum.ERROR_SIGN_UP_FAILED});
            } finally {
                dispatch(AuthenticationActions.setLoading(false));
            }
        }),
    authenticate: createAsyncThunk<AuthenticateWebResponseBody, AuthenticateWebRequestBody>(`${AuthenticationSliceName}/authenticate`,
        async (payload, {dispatch, rejectWithValue}) => {
            dispatch(AuthenticationActions.setLoading(true));
            try {
                const res = await AuthenticationApiService.authenticate(payload);
                return res.data.result;
            } catch (error) {
                if (axios.isAxiosError(error) && error.response?.status === 404) {
                    dispatch(AuthenticationActions.showVerifyEmailComponent(true));

                    return rejectWithValue({showVerifyEmailComponent: true, notification: error.response?.data?.Message});
                }

                return rejectWithValue({notification: NotificationEnum.ERROR_LOGIN_FAILED});
            } finally {
                dispatch(AuthenticationActions.setLoading(false));
            }
        }),
    verifyEmail: createAsyncThunk<AuthenticateWebResponseBody, VerifyEmailWebRequestBody>(`${AuthenticationSliceName}/verifyEmail`,
        async (payload, {dispatch, rejectWithValue}) => {
            dispatch(AuthenticationActions.setLoading(true));
            try {
                const res = await AuthenticationApiService.verifyEmail(payload);
                return res.data.result;
            } catch (error) {
                if (axios.isAxiosError(error) && error.response?.status === 404) {
                    return rejectWithValue({notification: error.response?.data?.Message});
                }

                return rejectWithValue({notification: NotificationEnum.ERROR_EMAIL_VERIFICATION_FAILED});
            } finally {
                dispatch(AuthenticationActions.setLoading(false));
            }
        }),
    refreshToken: createAsyncThunk<AuthenticateWebResponseBody>(`${AuthenticationSliceName}/refreshToken`,
        async (_, {dispatch, rejectWithValue}) => {
            dispatch(AuthenticationActions.setLoading(true));
            try {
                const res = await AuthenticationApiService.refreshToken();
                return res.data.result;
            } catch {
                return rejectWithValue({});
            } finally {
                dispatch(AuthenticationActions.setLoading(false));
            }
        }),
    logout: createAsyncThunk(`${AuthenticationSliceName}/logout`,
        async (_, {dispatch, rejectWithValue}) => {
            dispatch(AuthenticationActions.setLoading(true));
            try {
                await AuthenticationApiService.logout();
            } catch {
                return rejectWithValue({});
            } finally {
                dispatch(AuthenticationActions.setLoading(false));
            }
        }),
    forgotPassword: createAsyncThunk<ApiResponseBody<any>, ForgotPasswordWebRequestBody>(`${AuthenticationSliceName}/forgotPassword`,
        async (payload, {dispatch, rejectWithValue}) => {
            dispatch(AuthenticationActions.setLoading(true));
            try {
                const res = await AuthenticationApiService.forgotPassword(payload);
                dispatch(AuthenticationActions.showResetPasswordComponent(true));

                return res.data;
            } catch (error) {
                if (axios.isAxiosError(error) && error.response?.status === 404) {
                    return rejectWithValue({notification: error.response?.data?.Message});
                }

                return rejectWithValue({notification: NotificationEnum.ERROR_FORGOT_PASSWORD_FAILED});
            } finally {
                dispatch(AuthenticationActions.setLoading(false));
            }
        }),
    resetPassword: createAsyncThunk<AuthenticateWebResponseBody, ResetPasswordWebRequestBody>(`${AuthenticationSliceName}/resetPassword`,
        async (payload, {dispatch, rejectWithValue}) => {
            dispatch(AuthenticationActions.setLoading(true));
            try {
                const res = await AuthenticationApiService.resetPassword(payload);
                return res.data.result;
            } catch (error) {
                if (axios.isAxiosError(error) && error.response?.status === 404) {
                    return rejectWithValue({notification: error.response?.data?.Message});
                }

                return rejectWithValue({notification: NotificationEnum.ERROR_LOGIN_FAILED});
            } finally {
                dispatch(AuthenticationActions.setLoading(false));
            }
        }),
}
