import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Environments } from '../../environments/environments';
import { ApplicationState, ApplicationStore } from '../state/store';
import { ApiResponseBody } from '../models/ApiResponse';
import { AuthenticateWebResponseBody } from '../models/AuthenticationApi.model';
import { AuthenticationActions } from '../../authentication/state/authentication.slice';

const axiosInstance = axios.create({
    baseURL: Environments.ApiUrl,
});

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
    config.headers.Authorization = 'Bearer ' + (ApplicationStore.getState() as ApplicationState).authentication.token;
    return config;
});

axiosInstance.interceptors.response.use((response: AxiosResponse) => response, async (error: any) => {

    const originalRequest = error.config;

    if (
        error.response.status === 401
        && !originalRequest._retry && originalRequest.url !== `Auth/RefreshToken`
    ) {
        const response: AxiosResponse<ApiResponseBody<AuthenticateWebResponseBody>> = await axiosInstance.post(`Auth/RefreshToken`, {});

        const data = response.data.result;

        ApplicationStore.dispatch(AuthenticationActions.setToken(data.jwtToken));

        originalRequest._retry = true;
        originalRequest.headers.Authorization = 'Bearer ' + data.jwtToken;

        return axios(originalRequest);

    } else if (error.response.status === 401 && originalRequest.url === `Auth/RefreshToken`) {
        ApplicationStore.dispatch(AuthenticationActions.logoutSync());
    }

    return Promise.reject(error);
})

export const AxiosInstance = axiosInstance;
