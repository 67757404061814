import {
    AuthenticateWebRequestBody,
    AuthenticateWebResponseBody,
    ForgotPasswordWebRequestBody,
    ResetPasswordWebRequestBody,
    SignUpWebRequestBody,
    VerifyEmailWebRequestBody
} from '../models/AuthenticationApi.model';
import { AxiosInstance } from './axios';
import {ApiResponse, ApiResponseBody} from '../models/ApiResponse';

const PATH = 'Auth'

export const AuthenticationApiService = {
    signUp: (data: SignUpWebRequestBody): ApiResponse<AuthenticateWebResponseBody> => {
        return AxiosInstance.post(`${PATH}/SignUp`, data);
    },
    authenticate: (data: AuthenticateWebRequestBody): ApiResponse<AuthenticateWebResponseBody> => {
        return AxiosInstance.post(`${PATH}/AuthenticateWeb`, data);
    },
    verifyEmail: (data: VerifyEmailWebRequestBody): ApiResponse<AuthenticateWebResponseBody> => {
        return AxiosInstance.post(`${PATH}/VerifyEmail`, data);
    },
    forgotPassword: (data: ForgotPasswordWebRequestBody): ApiResponse<ApiResponseBody<any>> => {
        return AxiosInstance.post(`${PATH}/ForgotPassword`, data);
    },
    resetPassword: (data: ResetPasswordWebRequestBody): ApiResponse<AuthenticateWebResponseBody> => {
        return AxiosInstance.post(`${PATH}/ResetPassword`, data);
    },
    refreshToken: (): ApiResponse<AuthenticateWebResponseBody> => {
        return AxiosInstance.post(`${PATH}/RefreshToken`, {});
    },
    logout: (): ApiResponse<void> => {
        return AxiosInstance.post(`${PATH}/LogOut`, {});
    }
}
