import { ApplicationState } from '../../core/state/store';
import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state: ApplicationState) => state.common;

export const CommonSelectors = {
    selectOutcomes: createSelector(selectSelf, state => state.outcome),
    selectProteinTargetings: createSelector(selectSelf, state => state.proteinTargeting),
    selectSpecies: createSelector(selectSelf, state => state.species),
    selectCondition: createSelector(selectSelf, state => state.condition),
    selectConditions: createSelector(selectSelf, state => state.conditions),
    selectTarget: createSelector(selectSelf, state => state.target),
    selectTargets: createSelector(selectSelf, state => state.targets),
    selectCompound: createSelector(selectSelf, state => state.compound),
    selectCompounds: createSelector(selectSelf, state => state.compounds),
    selectIsLoading: createSelector(selectSelf, state => state.isLoading),
    selectNotifications: createSelector(selectSelf, state => state.notifications)
}
