import {  unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';

export const lightTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#0C3C60'
        },
        secondary: {
            main: '#6EA4CA',
        },
        background: {
            default: '#F5F5F5'
        }
    },
    shape: {
        borderRadius: 4
    },
    typography: {
        fontFamily: 'Roboto'
    }
});
