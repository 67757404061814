import React, { useCallback, useEffect, useState } from 'react';
import { Alert } from '@material-ui/lab';
import { AppNotification } from '../models/notification';
import { Snackbar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface NotificationHandlerProps {
    notification?: AppNotification;
    onDismiss: () => void;
    duration?: number
}

const NotificationHandler = ({notification, onDismiss, duration = 3000}: NotificationHandlerProps) => {

    const [isOpen, setOpen] = useState<boolean>(false);
    const {t} = useTranslation('notifications');

    const onCloseHandler = useCallback(() => {
        setOpen(false);
        setTimeout(() => {
            onDismiss();
        }, 100);
    }, [onDismiss]);

    useEffect(() => {
        setOpen(!!notification);
    }, [notification, setOpen])

    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={duration}
            onClose={onCloseHandler}
        >
            <Alert severity={notification?.type} elevation={6} variant={'filled'}>
                {t(notification?.message || '')}
            </Alert>
        </Snackbar>
    )
}

export default NotificationHandler;
