import {Button, Grid, Paper, Typography} from "@material-ui/core";
import SearchBar from "material-ui-search-bar";
import {StudyActions} from "../state/study.slice";
import {Search} from "@material-ui/icons";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import {createStyleSheet} from "../../core/utils/theming";
import {useAppDispatch} from "../../core/state/store";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {useSelector} from "react-redux";
import {StudySelectors} from "../state/study.selectors";
import {useCallback, useEffect} from "react";
import moment from "moment";
import {useHistory} from "react-router-dom";
import {ConfigSelectors} from "../../config/state/config.selectors";
import {ConfigActions} from "../../config/state/config.slice";
import {CatalogueTypes} from "../../config/state/config.state";

const useStyles = createStyleSheet((theme) => ({
    mobileAppBody:{
        [theme.breakpoints.down('sm')]: {
            margin: 0
        },
    }
}));

const MyStudies = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('studies');
    const { handleSubmit } = useForm();
    const keywords = useSelector(StudySelectors.selectKeywords);
    const myStudies = useSelector(StudySelectors.selectMyStudies);
    const styles = useStyles();
    const history = useHistory();
    const catalogues = useSelector(ConfigSelectors.selectCatalogues);

    useEffect(() => {
        dispatch(ConfigActions.getCatalogues());
        dispatch(StudyActions.fetchMyStudies());
    }, [dispatch]);

    const onSubmitSearch = useCallback(() => {
        dispatch(StudyActions.fetchMyStudies(keywords));
    }, [dispatch, keywords])

    const dateFormatter = (params: any) => {
        return moment(params.value).format('MMMM YYYY');
    };

    const gridActionCellRenderer = (props: any) => {
        return (
            <span>
                <button onClick={() => history.push(`/submit/${props.value}`)}>Edit</button>
            </span>
        );
    };

    function getCatalogueLabel(catalogueId: CatalogueTypes): string {
        if (catalogues.length === 0) return '';

        return catalogues.find(catalogue => catalogue.catalogueId === catalogueId)?.label ?? '';
    }

    return (
        <Paper className={`App-body ${styles.mobileAppBody}`} variant={'outlined'} square>

            <Grid container spacing={2} direction={'column'} style={{height: '100%'}}>
                <Grid item>
                    <Typography variant={'h3'} color={'primary'}>
                        {t('my_studies_title')}
                    </Typography>
                </Grid>
                <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                    <Grid item xs>
                        <SearchBar
                            value={keywords}
                            onChange={(newValue) => dispatch(StudyActions.setKeyword(newValue))}
                            onRequestSearch={() => dispatch(StudyActions.setKeyword(keywords))}
                            onCancelSearch={() => dispatch(StudyActions.setKeyword(''))}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            size={'large'}
                            variant={'contained'}
                            color={'primary'}
                            onClick={handleSubmit(onSubmitSearch)}
                            startIcon={<Search />}
                        >{t('button_search')}</Button>
                    </Grid>
                </Grid>

                <Grid xs item className="ag-theme-alpine" style={{ width: '100%' }}>
                    <AgGridReact rowData={myStudies} frameworkComponents={{btnCellRenderer: gridActionCellRenderer,}}>
                        <AgGridColumn headerName={t('group_study_info')}>
                            <AgGridColumn headerName={t('actions')} field="studyId" headerTooltip={t('actions')} resizable cellRenderer='btnCellRenderer'/>
                            <AgGridColumn headerName={t('id')} field="studyId" filter="agNumberColumnFilter" sortable headerTooltip={t('id')} resizable tooltipField="id" />
                            <AgGridColumn headerName={t('name')} field="name" filter="agTextColumnFilter" sortable headerTooltip={t('name')} resizable tooltipField="name" />
                            <AgGridColumn headerName={t('contactName')} field="contactName" filter="agTextColumnFilter" sortable headerTooltip={t('contactName')} resizable tooltipField="contactName" />
                            <AgGridColumn headerName={t('contactEmail')} field="contactEmail" filter="agTextColumnFilter" sortable headerTooltip={t('contactEmail')} resizable tooltipField="contactEmail" />
                            <AgGridColumn headerName={t('completion')} field="completion" filter="agDateColumnFilter" sortable headerTooltip={t('completion')} resizable valueFormatter={dateFormatter} tooltipField="completion" />
                            <AgGridColumn headerName={t('isPublished')} field="isPublished" filter="agTextColumnFilter" sortable headerTooltip={t('isPublished')} resizable tooltipField="isPublished" />
                            <AgGridColumn headerName={t('link')} field="link" filter="agTextColumnFilter" sortable headerTooltip={t('link')} resizable tooltipField="link" />
                        </AgGridColumn>
                        <AgGridColumn headerName={t('group_study_base')}>
                            <AgGridColumn headerName={getCatalogueLabel(CatalogueTypes.Outcome)} field="outcome" filter="agTextColumnFilter" sortable headerTooltip={getCatalogueLabel(CatalogueTypes.Outcome)} resizable tooltipField="outcome" />
                            <AgGridColumn headerName={getCatalogueLabel(CatalogueTypes.ProteinTargeting)} field="proteinTargeting" filter="agTextColumnFilter" sortable headerTooltip={getCatalogueLabel(CatalogueTypes.ProteinTargeting)} resizable tooltipField="proteinTargeting" />
                            <AgGridColumn headerName={getCatalogueLabel(CatalogueTypes.Species)} field="species" filter="agTextColumnFilter" sortable headerTooltip={getCatalogueLabel(CatalogueTypes.Species)} resizable tooltipField="species" />
                            <AgGridColumn headerName={t('condition')} field="condition" filter="agTextColumnFilter" sortable headerTooltip={t('condition')} resizable tooltipField="condition" />
                            <AgGridColumn headerName={t('target')} field="target" filter="agTextColumnFilter" sortable headerTooltip={t('target')} resizable tooltipField="target" />
                            <AgGridColumn headerName={t('compound')} field="compound" filter="agTextColumnFilter" sortable headerTooltip={t('compound')} resizable tooltipField="compound" />
                            <AgGridColumn headerName={t('isTissueAvailable')} field="isTissueAvailable" filter="agTextColumnFilter" sortable headerTooltip={t('isTissueAvailable')} resizable tooltipField="isTissueAvailable" />
                            <AgGridColumn headerName={t('tissueType')} field="tissueType" filter="agTextColumnFilter" sortable headerTooltip={t('tissueType')} resizable tooltipField="tissueType" />
                            <AgGridColumn headerName={t('outcomeMeasure')} field="outcomeMeasure" filter="agTextColumnFilter" sortable headerTooltip={t('outcomeMeasure')} resizable tooltipField="outcomeMeasure" />
                        </AgGridColumn>
                        <AgGridColumn headerName={t('group_study_intervention')}>
                            <AgGridColumn headerName={t('interventionGroupNumberOfAnimals')} field="testGroupNumberOfAnimals" filter="agNumberColumnFilter" sortable headerTooltip={t('interventionGroupNumberOfAnimals')} resizable tooltipField="testGroupNumberOfAnimals" />
                            <AgGridColumn headerName={t('interventionGroupMeans')} field="testGroupMeans" filter="agNumberColumnFilter" sortable headerTooltip={t('interventionGroupMeans')} resizable tooltipField="testGroupMeans" />
                            <AgGridColumn headerName={t('interventionGroupStandardDeviation')} field="testGroupStandardDeviation" filter="agNumberColumnFilter" sortable headerTooltip={t('interventionGroupStandardDeviation')} resizable tooltipField="testGroupStandardDeviation" />
                        </AgGridColumn>
                        <AgGridColumn headerName={t('group_study_control')}>
                            <AgGridColumn headerName={t('controlGroupNumberOfAnimals')} field="controlGroupNumberOfAnimals" filter="agNumberColumnFilter" sortable headerTooltip={t('controlGroupNumberOfAnimals')} resizable tooltipField="controlGroupNumberOfAnimals" />
                            <AgGridColumn headerName={t('controlGroupMeans')} field="controlGroupMeans" filter="agNumberColumnFilter" sortable headerTooltip={t('controlGroupMeans')} resizable tooltipField="controlGroupMeans" />
                            <AgGridColumn headerName={t('controlGroupStandardDeviation')} field="controlGroupStandardDeviation" filter="agNumberColumnFilter" sortable headerTooltip={t('controlGroupStandardDeviation')} resizable tooltipField="controlGroupStandardDeviation" />
                        </AgGridColumn>
                    </AgGridReact>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default MyStudies;
