import React from 'react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';

const LoadingComponent = () => {
    return (
        <Grid container direction={'column'} style={{height: '100vh'}} alignItems={'center'}  spacing={4}>
            <Grid item>
                <CircularProgress color={'primary'} size={92}/>
            </Grid>
            <Grid item>
                <Typography variant={'h3'} color={'primary'}>Loading...</Typography>
            </Grid>
        </Grid>
    )
}

export default LoadingComponent;
