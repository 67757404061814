import { createAsyncThunk } from '@reduxjs/toolkit';
import { StudySliceName } from './study.state';
import {CreateStudy, Study, UpdateStudyWebRequestBody} from '../../core/models/Study';
import { StudyApiService } from '../../core/services/study.api.service';

export const StudyThunks = {

    fetch: createAsyncThunk<Study[], string>(`${StudySliceName}/FetchStudies`,
        async (payload) => {
            const res = await StudyApiService.fetch(payload);
            return res.data.result;
        }),
    create: createAsyncThunk<Study, Partial<CreateStudy>>(`${StudySliceName}/Create`,
        async (payload, {rejectWithValue}) => {
            try{
                const res = await StudyApiService.createStudy(payload);
                return res.data.result;
            }
            catch {
                return rejectWithValue({});
            }
        }),
    update: createAsyncThunk<Study, UpdateStudyWebRequestBody>(`${StudySliceName}/Update`,
        async (payload, {rejectWithValue}) => {
            try{
                const res = await StudyApiService.updateStudy(payload);
                return res.data.result;
            }
            catch {
                return rejectWithValue({});
            }
        }),
    fetchMyStudies: createAsyncThunk<Study[], string | undefined>(`${StudySliceName}/GetMyStudies`,
        async (payload = '', {rejectWithValue}) => {
            try{
                const res = await StudyApiService.fetchMyStudies(payload);
                return res.data.result;
            }
            catch {
                return rejectWithValue({});
            }
        }),
    getStudy: createAsyncThunk<CreateStudy, string>(`${StudySliceName}/GetStudy`,
        async (payload, {rejectWithValue}) => {
            try{
                const res = await StudyApiService.getStudy(payload);
                return res.data.result;
            }
            catch {
                return rejectWithValue({});
            }
        }),
}
