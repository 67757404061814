import {Button, Card, CardContent, Grid, TextField, Typography} from '@material-ui/core';
import {ExitToApp} from '@material-ui/icons';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {AuthenticationActions} from '../state/authentication.slice';
import {AuthenticationSelectors} from '../state/authentication.selectors';
import {useHistory} from 'react-router-dom';
import NotificationHandler from "../../core/components/NotificationHandler";
import {useCallback, useEffect} from "react";
import {authenticationComponentStyles} from "../../core/styles/authenticationComponentStyles";

interface LoginFormProps {
    email: string;
    password: string;
}

const Login = ()=> {
    const styles = authenticationComponentStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const {control, handleSubmit, errors} = useForm();
    const {t} = useTranslation(['authentication', 'validation']);
    const isLoading = useSelector(AuthenticationSelectors.selectLoading);
    const notifications = useSelector(AuthenticationSelectors.selectNotifications);
    const showVerifyEmailComponent = useSelector(AuthenticationSelectors.selectShowVerifyEmailComponent);

    useEffect(() => {
        if (showVerifyEmailComponent) {
            history.push('/verify-email');
        }
    }, [showVerifyEmailComponent, history]);

    const onSubmit = ({email, password}: LoginFormProps) => {
        dispatch(AuthenticationActions.authenticate({
            email: email,
            password: password
        }));
    }

    const onDismissNotification = useCallback(() => {
        dispatch(AuthenticationActions.dismissNotification());
    }, [dispatch]);

    return (
        <div className={styles.root}>
            <div className={styles.cardContainer}>
                <Card className={styles.card}>
                    <CardContent className={styles.cardContent}>
                        <div className={styles.cardHeader}>
                            <Typography variant={'h3'} color={'primary'} style={{ fontWeight: 'bold' }}>RepoTrial</Typography>
                        </div>
                        <Card elevation={12} className={styles.cardBody}>
                            <CardContent style={{height: '100%'}}>
                                <form onSubmit={handleSubmit(onSubmit)} style={{height: '100%'}}>
                                    <Grid
                                        container
                                        direction={'column'}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                        style={{height: '100%'}}
                                    >
                                        <Grid item>
                                            <Typography variant={'h4'}
                                                        color={'primary'}>{t('label_login')}</Typography>
                                        </Grid>

                                        <Grid item container direction={'column'} spacing={errors.username ? 0 : 2}>
                                            <Grid item>
                                                <Controller
                                                    defaultValue={''}
                                                    control={control}
                                                    rules={{required: true}}
                                                    name={'email'}
                                                    render={
                                                        ({onBlur, onChange, value}, {invalid}) => <TextField
                                                            type={'email'}
                                                            className={styles.cardTextInput}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            error={invalid}
                                                            label={t('label_email')}
                                                            variant="outlined"
                                                            helperText={invalid ? t('required', {ns: 'validation'}) : undefined}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Controller
                                                    defaultValue={''}
                                                    name={'password'}
                                                    control={control}
                                                    rules={{required: true}}
                                                    render={
                                                        ({onBlur, onChange, value}, {invalid}) => <TextField
                                                            type={'password'}
                                                            className={styles.cardTextInput}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            error={invalid}
                                                            label={t('label_password')}
                                                            variant="outlined"
                                                            helperText={invalid ? t('required', {ns: 'validation'}) : undefined}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                color={'primary'}
                                                type={'submit'}
                                                variant={'contained'}
                                                startIcon={<ExitToApp/>}
                                                disabled={isLoading}
                                                onSubmit={handleSubmit(onSubmit)}
                                            >
                                                {t('button_login')}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                color={'primary'}
                                                type={'submit'}
                                                variant={'contained'}
                                                onClick={() => history.push('/sign-up')}
                                            >
                                                {t('button_sign_up')}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                color={'primary'}
                                                type={'submit'}
                                                variant={'text'}
                                                onClick={() => history.push('/forgot-password')}
                                            >
                                                {t('button_forgot_password')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </CardContent>
                </Card>
            </div>
            <NotificationHandler onDismiss={onDismissNotification} notification={notifications[0]}/>
        </div>
    );
}

export default Login;
