export enum NotificationType {
    ERROR = 'error',
    WARNING ='warning',
    INFO = 'info',
    SUCCESS = 'success'
}

export interface AppNotification {
    message: string;
    type: NotificationType;
}

export const createErrorNotification: (msg: string) => AppNotification = (msg: string) => ({ message: msg, type: NotificationType.ERROR });
export const createWarningNotification: (msg: string) => AppNotification = (msg: string) => ({ message: msg, type: NotificationType.WARNING });
export const createInfoNotification: (msg: string) => AppNotification = (msg: string) => ({ message: msg, type: NotificationType.INFO });
export const createSuccessNotification: (msg: string) => AppNotification = (msg: string) => ({ message: msg, type: NotificationType.SUCCESS });
