import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    ListSubheader,
    Paper,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {useTranslation} from "react-i18next";
import DateFnsUtils from '@date-io/date-fns';
import React, {useCallback, useEffect, useRef} from "react";
import {CommonActions} from "../../common/state/common.slice";
import {CommonSelectors} from '../../common/state/common.selectors';
import {useAppDispatch} from "../../core/state/store";
import {useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {CreateStudy} from "../../core/models/Study";
import {joiResolver} from '@hookform/resolvers/joi';
import Joi from 'joi';
import {Validation, ValidationError} from '../../core/utils/validation';
import {StudyActions} from "../state/study.slice";
import NotificationHandler from '../../core/components/NotificationHandler';
import {StudySelectors} from "../state/study.selectors";
import Autocomplete, {AutocompleteRenderGroupParams} from '@material-ui/lab/Autocomplete';
import {ListChildComponentProps, VariableSizeList} from 'react-window';
import {Catalogue, CatalogueEntry, Compound, Condition, emptyCatalogue, Target} from "../../core/models/Common";
import {AuthenticationSelectors} from "../../authentication/state/authentication.selectors";
import {useParams} from "react-router-dom";
import {ConfigSelectors} from "../../config/state/config.selectors";
import {ConfigActions} from "../../config/state/config.slice";
import {createStyleSheet} from "../../core/utils/theming";
import {CommonType} from "../../common/state/common.state";

const useStyles = createStyleSheet((theme) => ({
    mobileAppBody:{
        [theme.breakpoints.down('sm')]: {
            margin: 0
          },
    },
    studySubmitCriteria: {
        color: theme.palette.warning.main,
        marginTop: 15
    },
    rootFormHelperText: {
        marginLeft: 0,
        marginTop: 4,
        marginRight: 0,
    },
}));

const SubmitStudy = () => {
    const { t } = useTranslation(['studies', 'validation']);
    const dispatch = useAppDispatch();
    const outcomes = useSelector(CommonSelectors.selectOutcomes)
    const proteinTargetings = useSelector(CommonSelectors.selectProteinTargetings)
    const species = useSelector(CommonSelectors.selectSpecies)
    const condition = useSelector(CommonSelectors.selectCondition)
    const conditions = useSelector(CommonSelectors.selectConditions)
    const target = useSelector(CommonSelectors.selectTarget)
    const targets = useSelector(CommonSelectors.selectTargets)
    const compounds = useSelector(CommonSelectors.selectCompounds)
    const compound = useSelector(CommonSelectors.selectCompound)
    const notifications = useSelector(StudySelectors.selectNotifications);
    const loggedInUser = useSelector(AuthenticationSelectors.selectLoggedInUser);
    const study = useSelector(StudySelectors.selectStudy);
    const styles = useStyles();
    const params = useParams<{ studyId?: string }>();
    const catalogues = useSelector(ConfigSelectors.selectCatalogues);
    const outcomeCatalogueData = useRef(emptyCatalogue);
    const proteinTargetingCatalogueData = useRef(emptyCatalogue);
    const speciesCatalogueData = useRef(emptyCatalogue);
    var searchTimeout: NodeJS.Timeout;

    const userSchema = Joi.object<CreateStudy>({
        name: Joi.string().max(80).required(),
        contactName: Joi.string().max(80).required(),
        contactEmail: Joi.string().email({ tlds: { allow: false } }).required(),
        completion: Joi.date(),
        isPublished: Joi.boolean(),
        link: Joi.when('isPublished', { is: true, then: Joi.string().max(2000).required() }),
        outcome: outcomeCatalogueData.current.isRequired ? Joi.object<CatalogueEntry>().required().not({}) : Joi.object<CatalogueEntry>().allow({}, null),
        proteinTargeting: proteinTargetingCatalogueData.current.isRequired ? Joi.object<CatalogueEntry>().required().not({}) : Joi.object<CatalogueEntry>().allow({}, null),
        species: speciesCatalogueData.current.isRequired ? Joi.object<CatalogueEntry>().required().not({}) : Joi.object<CatalogueEntry>().allow({}, null),
        condition: Joi.object<Condition>().allow('', null, {}),
        target: Joi.object<Target>().allow('', null, {}),
        compound: Joi.object<Compound>().allow('', null, {}),
        isTissueAvailable: Joi.boolean(),
        tissueType: Joi.when('isTissueAvailable', { is: true, then: Joi.string().max(2000).required() }),
        outcomeMeasure: Joi.string().max(80),
        testGroupNumberOfAnimals: Joi.number().allow('', null)
            .custom((val, helpers) => {
                if (!val && +val !== 0) {
                    return val;
                } else if (+val <= 0) {
                    return helpers.error(ValidationError.NUMBER_MIN);
                } else if (+val >= 100000) {
                    return helpers.error(ValidationError.NUMBER_MAX);
                }

                return val;
            }),
        testGroupMeans: Joi.number().allow('', null)
            .custom((val, helpers) => {
                if (!val && +val !== 0) {
                    return val;
                } else if (+val <= 0) {
                    return helpers.error(ValidationError.NUMBER_MIN);
                } else if (+val >= 100000) {
                    return helpers.error(ValidationError.NUMBER_MAX);
                }

                return val;
            }),
        testGroupStandardDeviation: Joi.number().allow('', null)
            .custom((val, helpers) => {
                if (!val && +val !== 0) {
                    return val;
                } else if (+val <= 0) {
                    return helpers.error(ValidationError.NUMBER_MIN);
                } else if (+val >= 100000) {
                    return helpers.error(ValidationError.NUMBER_MAX);
                }

                return val;
            }),
        controlGroupNumberOfAnimals: Joi.number().allow( '', null)
            .custom((val, helpers) => {
                if (!val && +val !== 0) {
                    return val;
                } else if (+val <= 0) {
                    return helpers.error(ValidationError.NUMBER_MIN);
                } else if (+val >= 100000) {
                    return helpers.error(ValidationError.NUMBER_MAX);
                }

                return val;
            }),
        controlGroupMeans: Joi.number().allow('', null)
            .custom((val, helpers) => {
                if (!val && +val !== 0) {
                    return val;
                } else if (+val <= 0) {
                    return helpers.error(ValidationError.NUMBER_MIN);
                } else if (+val >= 100000) {
                    return helpers.error(ValidationError.NUMBER_MAX);
                }

                return val;
            }),
        controlGroupStandardDeviation: Joi.number().allow( '', null)
            .custom((val, helpers) => {
                if (!val && +val !== 0) {
                    return val;
                } else if (+val <= 0) {
                    return helpers.error(ValidationError.NUMBER_MIN);
                } else if (+val >= 100000) {
                    return helpers.error(ValidationError.NUMBER_MAX);
                }

                return val;
            }),
    })

    const { control, watch, handleSubmit, errors, reset } = useForm<CreateStudy>({
        defaultValues: {},
        resolver: joiResolver(userSchema)
    });

    useEffect(() => {
        dispatch(ConfigActions.getCatalogues());
        dispatch(CommonActions.outcomes());
        dispatch(CommonActions.proteinTargetings());
        dispatch(CommonActions.species());

        if (!study && params.studyId) {
            dispatch(StudyActions.getStudy(params.studyId));
        }
    }, [dispatch, params, study]);

    useEffect(() => {
        function setFormElementValues() {
            if (!study) return;

            if (study.conditionId) {
                dispatch(CommonActions.condition(study.conditionId));
            }

            if (study.targetId) {
                dispatch(CommonActions.target(study.targetId));
            }

            if (study.compoundId) {
                dispatch(CommonActions.compound(study.compoundId));
            }

            control.setValue('name', study.name ?? '');
            control.setValue('contactName', study.contactName ?? '');
            control.setValue('contactEmail', study.contactEmail ?? '');
            control.setValue('completion', study.completion ?? '');
            control.setValue('isPublished', study.isPublished ?? false);

            control.setValue('outcome', getCatalogueEntry(outcomes, study.outcomeId));
            control.setValue('proteinTargeting', getCatalogueEntry(proteinTargetings, study.proteinTargetingId));
            control.setValue('species', getCatalogueEntry(species, study.speciesId));
            control.setValue('isTissueAvailable', study.isTissueAvailable ?? false);
            control.setValue('outcomeMeasure', study.outcomeMeasure ?? '');

            control.setValue('testGroupNumberOfAnimals', study.testGroupNumberOfAnimals ?? '');
            control.setValue('testGroupMeans', study.testGroupMeans ?? '');
            control.setValue('testGroupStandardDeviation', study.testGroupStandardDeviation ?? '');

            control.setValue('controlGroupNumberOfAnimals', study.controlGroupNumberOfAnimals ?? '');
            control.setValue('controlGroupMeans', study.controlGroupMeans ?? '');
            control.setValue('controlGroupStandardDeviation', study.controlGroupStandardDeviation ?? '');

            setTimeout(() => {
                control.setValue('link', study.link ?? '');
                control.setValue('tissueType', study.tissueType ?? '');
            }, 500);
        }

        setFormElementValues();
    }, [dispatch, study, outcomes, proteinTargetings, species, control]);

    useEffect(() => {
        if (!study) return;

        if (study.conditionId && condition) {
            control.setValue('condition', condition);
        }

        if (study.targetId && target) {
            control.setValue('target', target);
        }

        if (study.compoundId && compound) {
            control.setValue('compound', compound);
        }
    }, [study, condition, target, compound, control]);

    function getCatalogueEntry(entries: CatalogueEntry[], entryId: number) {
        return entries.find((catalogueEntry) => catalogueEntry.catalogueEntryId === entryId) ?? {};
    }

    useEffect(() => {
        function getCatalogueData(catalogueEntities: CatalogueEntry[]): Catalogue {
            if (catalogues.length === 0 || (!catalogueEntities || catalogueEntities.length === 0)) return emptyCatalogue;

            const catalogueId = catalogueEntities[0].catalogueId;
            const currentCatalogue = catalogues.find((catalogue) => catalogue.catalogueId === catalogueId);
            const activeCatalogueEntries: CatalogueEntry[] | [] = catalogueEntities.filter(catalogueEntity => catalogueEntity.isActive) ?? [];

            return {
                catalogueId: currentCatalogue?.catalogueId ?? null,
                label: currentCatalogue?.label ?? null,
                isRequired: currentCatalogue?.isRequired ?? null,
                catalogueEntries: activeCatalogueEntries,
            };
        }

        outcomeCatalogueData.current = getCatalogueData(outcomes);
        proteinTargetingCatalogueData.current = getCatalogueData(proteinTargetings);
        speciesCatalogueData.current = getCatalogueData(species);

        if (!study) {
            control.setValue('outcome', getFirstDefaultCatalogueEntry(outcomeCatalogueData.current.catalogueEntries));
            control.setValue('proteinTargeting', getFirstDefaultCatalogueEntry(proteinTargetingCatalogueData.current.catalogueEntries));
            control.setValue('species', getFirstDefaultCatalogueEntry(speciesCatalogueData.current.catalogueEntries));
        }
    }, [study, outcomes, catalogues, outcomeCatalogueData, proteinTargetingCatalogueData, proteinTargetings, species, speciesCatalogueData, control]);

    function getFirstDefaultCatalogueEntry(entries: CatalogueEntry[]) {
        return entries.find((catalogueEntry) => catalogueEntry.isDefault) ?? {};
    }

    function handleInputValueChange(inputValue: string, commonType: CommonType) {
        if (searchTimeout !== undefined) clearTimeout(searchTimeout);

        searchTimeout = setTimeout(() => getSelectOptionsByType(inputValue, commonType), 350);
    }

    function getSelectOptionsByType(inputValue: string, commonType: CommonType) {
        switch (commonType) {
            case CommonType.CONDITION: {
                getConditionOptions(inputValue)
                break;
            }
            case CommonType.TARGET: {
                getTargetOptions(inputValue);
                break;
            }
            case CommonType.COMPOUND: {
                getCompoundOptions(inputValue);
                break;
            }
            default:
                break;
        }
    }

    function getConditionOptions(inputValue: string) {
        if (inputValue.length < 3) {
            dispatch(CommonActions.clearConditions());

            return;
        }

       dispatch(CommonActions.fetchConditions(inputValue));
    }

    function getTargetOptions(inputValue: string) {
        if (inputValue.length < 3) {
            dispatch(CommonActions.clearTargets());

            return;
        }

       dispatch(CommonActions.fetchTargets(inputValue));
    }

    function getCompoundOptions(inputValue: string) {
        if (inputValue.length < 3) {
            dispatch(CommonActions.clearCompounds());

            return;
        }

       dispatch(CommonActions.fetchCompounds(inputValue));
    }

    useEffect(() => {
        return () => {
            dispatch(StudyActions.clearStudy());
            dispatch(CommonActions.clearConditions());
            dispatch(CommonActions.clearTargets());
            dispatch(CommonActions.clearCompounds());
        }
    }, [dispatch])

    const handleSave = useCallback((data: CreateStudy) => {
        data.outcomeId = data.outcome?.catalogueEntryId;
        data.proteinTargetingId = data.proteinTargeting?.catalogueEntryId;
        data.speciesId = data.species?.catalogueEntryId;
        data.conditionId = data.condition?.conditionId ?? null;
        data.targetId = data.target?.targetId ?? null;
        data.compoundId = data.compound?.compoundId ?? null;

        let newData: {[key: string]: any} = {};
        Object.entries(data).forEach(([key, val]) => (newData[key] = val !== '' && val !== {} ? val : null));

        if (study && study.studyId) {
            newData.studyId = study.studyId;
            dispatch(StudyActions.update({studyId: study.studyId, study: newData}));

            return;
        }

        dispatch(StudyActions.create(newData)).unwrap()
            .then(() => {
                reset();
            })
    }, [dispatch, reset, study]);

    const onDismissNotification = useCallback(() => {
        dispatch(StudyActions.dismissNotification());
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(StudyActions.clearNotifications());
        }
    }, [dispatch]);

    const studyFormValue = watch();

    const LISTBOX_PADDING = 8; // px

    function renderRow(props: ListChildComponentProps) {
        const { data, index, style } = props;
        return React.cloneElement(data[index], {
            style: {
                ...style,
                top: (style.top as number) + LISTBOX_PADDING,
            },
        });
    }

    const OuterElementContext = React.createContext({});

    const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
        const outerProps = React.useContext(OuterElementContext);
        return <div ref={ref} {...props} {...outerProps} />;
    });

    function useResetCache(data: any) {
        const ref = React.useRef<VariableSizeList>(null);
        React.useEffect(() => {
            if (ref.current != null) {
                ref.current.resetAfterIndex(0, true);
            }
        }, [data]);
        return ref;
    }

    // Adapter for react-window
    const ListboxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
        const { children, ...other } = props;
        const itemData = React.Children.toArray(children);
        const theme = useTheme();
        const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
        const itemCount = itemData.length;
        const itemSize = smUp ? 36 : 48;

        const getChildSize = (child: React.ReactNode) => {
            if (React.isValidElement(child) && child.type === ListSubheader) {
                return 48;
            }

            return itemSize;
        };

        const getHeight = () => {
            if (itemCount > 8) {
                return 8 * itemSize;
            }
            return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
        };

        const gridRef = useResetCache(itemCount);

        return (
            <div ref={ref}>
                <OuterElementContext.Provider value={other}>
                    <VariableSizeList
                        itemData={itemData}
                        height={getHeight() + 2 * LISTBOX_PADDING}
                        width="100%"
                        ref={gridRef}
                        outerElementType={OuterElementType}
                        innerElementType="ul"
                        itemSize={(index: any) => getChildSize(itemData[index])}
                        overscanCount={5}
                        itemCount={itemCount}
                        style={{
                            overflowX: 'hidden',
                        }}
                    >
                        {renderRow}
                    </VariableSizeList>
                </OuterElementContext.Provider>
            </div>
        );
    });

    const renderGroup = (params: AutocompleteRenderGroupParams) => [
        <ListSubheader key={params.key} component="div">
            {params.group}
        </ListSubheader>,
        params.children,
    ];

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Paper className={`App-body ${styles.mobileAppBody}`} variant={'outlined'} square>
                <Grid container spacing={2} direction={'column'}>
                    <Typography variant={'h3'} color={'primary'}>
                        {t('submit_title')}
                    </Typography>

                    { loggedInUser == null &&
                    <Typography variant={'h4'} className={styles.studySubmitCriteria}>
                        {t('submit_title_criteria')}
                    </Typography>
                    }

                    <h1>1. {t('group_study_info')}</h1>

                    {/* #### NAME INPUT #### */}
                    <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                        <Grid item sm={6} xs={12}>
                            <Controller
                                defaultValue={study?.name ?? ''}
                                control={control}
                                name={'name'}
                                render={
                                    ({ onBlur, onChange, value }, { invalid }) =>
                                        <TextField
                                            fullWidth={true}
                                            error={invalid}
                                            type={'text'}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            label={t('name')}
                                            variant={'outlined'}
                                        />
                                }
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <p className={'validation_message'}>
                                {Validation
                                    .toErrorMessage('name', ValidationError.STRING_EMPTY, errors, t('required', { ns: 'validation' })) ||
                                    Validation
                                        .toErrorMessage('name', ValidationError.STRING_MAX, errors, t('maxLength', {
                                            ns: 'validation',
                                            len: 80
                                        }))
                                }
                            </p>
                        </Grid>
                    </Grid>

                    {/* #### Contact NAME INPUT #### */}
                    <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                        <Grid item sm={6} xs={12}>
                            <Controller
                                defaultValue={''}
                                control={control}
                                name={'contactName'}
                                render={
                                    ({ onBlur, onChange, value }, { invalid }) =>
                                        <TextField
                                            fullWidth={true}
                                            error={invalid}
                                            type={'text'}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            label={t('contactName')}
                                            variant={'outlined'}
                                        />
                                }
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <p className={'validation_message'}>
                                {
                                    Validation
                                        .toErrorMessage('contactName', ValidationError.STRING_EMPTY, errors, t('required', { ns: 'validation' })) ||
                                    Validation
                                        .toErrorMessage('contactName', ValidationError.STRING_MAX, errors, t('maxLength', {
                                            ns: 'validation',
                                            len: 80
                                        }))
                                }
                            </p>
                        </Grid>
                    </Grid>

                    {/* #### Contact Email INPUT #### */}
                    <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                        <Grid item sm={6} xs={12}>
                            <Controller
                                defaultValue={''}
                                control={control}
                                name={'contactEmail'}
                                render={
                                    ({ onBlur, onChange, value }, { invalid }) =>
                                        <TextField
                                            fullWidth={true}
                                            error={invalid}
                                            type={'text'}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            label={t('contactEmail')}
                                            variant={'outlined'}
                                        />
                                }
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <p className={'validation_message'}>
                                {
                                    Validation
                                        .toErrorMessage('contactEmail', ValidationError.STRING_EMPTY, errors, t('required', { ns: 'validation' })) ||
                                    Validation
                                        .toErrorMessage('contactEmail', ValidationError.STRING_EMAIL, errors, t('email', { ns: 'validation' })) ||
                                    Validation
                                        .toErrorMessage('contactEmail', ValidationError.STRING_MAX, errors, t('maxLength', {
                                            ns: 'validation',
                                            len: 80
                                        }))
                                }
                            </p>

                        </Grid>
                    </Grid>

                    {/* #### Completion INPUT #### */}
                    <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                        <Grid item sm={6} xs={12}>
                            <Controller
                                defaultValue={new Date()}
                                control={control}
                                name={'completion'}
                                render={
                                    ({ onBlur, onChange, value }) =>
                                        <DatePicker
                                            variant="dialog"
                                            inputVariant="outlined"
                                            fullWidth={true}
                                            openTo="year"
                                            views={["year", "month"]}
                                            label={t('completion')}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                        />
                                }
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                        </Grid>
                    </Grid>

                    {/* #### IsPublished INPUT #### */}
                    <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                        <Grid item sm={6} xs={12}>
                            <Controller
                                defaultValue={false}
                                control={control}
                                name={'isPublished'}
                                render={
                                    ({ onBlur, onChange, value }) =>
                                        <FormControlLabel
                                            onBlur={onBlur}
                                            control={<Checkbox color="primary" checked={value} onChange={(event) => { onChange(event.target.checked) }} />}
                                            label={t('isPublished')}
                                            labelPlacement="end"
                                        />
                                }
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                        </Grid>
                    </Grid>
                    {
                        (studyFormValue.isPublished) &&
                        <>
                            {/* #### Link INPUT #### */}
                            <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                                <Grid item sm={6} xs={12}>
                                    <Controller
                                        defaultValue={''}
                                        control={control}
                                        name={'link'}
                                        render={
                                            ({ onBlur, onChange, value }, { invalid }) =>
                                                <TextField
                                                    fullWidth={true}
                                                    error={invalid}
                                                    type={'text'}
                                                    value={value}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    label={t('link')}
                                                    variant={'outlined'}
                                                />
                                        }
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <p className={'validation_message'}>
                                        {
                                            Validation
                                                .toErrorMessage('link', ValidationError.STRING_EMPTY, errors, t('required', { ns: 'validation' })) ||
                                            Validation
                                                .toErrorMessage('link', ValidationError.STRING_MAX, errors, t('maxLength', {
                                                    ns: 'validation',
                                                    len: 2000
                                                }))
                                        }
                                    </p>

                                </Grid>
                            </Grid>
                        </>
                    }

                    <h1>2. {t('group_study_base')}</h1>

                    {/* #### Outcome INPUT #### */}
                    <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                        <Grid item sm={6} xs={12}>
                            <Controller
                                defaultValue={{}}
                                control={control}
                                name={'outcome'}
                                render={
                                    ({ onBlur, onChange, value }, { invalid }) =>
                                        <Autocomplete
                                            id="outcome_select"
                                            disableListWrap
                                            ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
                                            renderGroup={renderGroup}
                                            value={value}
                                            onChange={(event, value) => onChange(value)}
                                            onBlur={onBlur}
                                            getOptionLabel={(option) => option.label ?? ""}
                                            options={outcomeCatalogueData.current.catalogueEntries}
                                            renderInput={(params) => <TextField {...params} variant="outlined" label={outcomeCatalogueData.current.label} error={invalid} />}
                                            renderOption={(option) => <Typography noWrap>{option.label}</Typography>}
                                        />
                                }
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <p className={'validation_message'}>
                                {
                                    Validation
                                        .toErrorMessage('outcome', ValidationError.OBJECT_BASE, errors, t('required', { ns: 'validation' }))
                                    ||
                                    Validation
                                        .toErrorMessage('outcome', ValidationError.INVALID_VALUE, errors, t('required', { ns: 'validation' }))
                                }
                            </p>

                        </Grid>
                    </Grid>

                    {/* #### ProteinTargeting INPUT #### */}
                    <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                        <Grid item sm={6} xs={12}>
                            <Controller
                                defaultValue={{}}
                                control={control}
                                name={'proteinTargeting'}
                                render={
                                    ({ onBlur, onChange, value }, { invalid }) =>
                                        <Autocomplete
                                            id="proteinTargeting_select"
                                            disableListWrap
                                            ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
                                            renderGroup={renderGroup}
                                            value={value}
                                            onChange={(event, value) => onChange(value)}
                                            onBlur={onBlur}
                                            getOptionLabel={(option) => option.label ?? ""}
                                            options={proteinTargetingCatalogueData.current.catalogueEntries}
                                            renderInput={(params) => <TextField {...params} variant="outlined" label={proteinTargetingCatalogueData.current.label} error={invalid} />}
                                            renderOption={(option) => <Typography noWrap>{option.label}</Typography>}
                                        />
                                }
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <p className={'validation_message'}>
                                {
                                    Validation
                                        .toErrorMessage('proteinTargeting', ValidationError.OBJECT_BASE, errors, t('required', { ns: 'validation' }))
                                    ||
                                    Validation
                                        .toErrorMessage('proteinTargeting', ValidationError.INVALID_VALUE, errors, t('required', { ns: 'validation' }))
                                }
                            </p>

                        </Grid>
                    </Grid>

                    {/* #### species INPUT #### */}
                    <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                        <Grid item sm={6} xs={12}>
                            <Controller
                                defaultValue={{}}
                                control={control}
                                name={'species'}
                                render={
                                    ({ onBlur, onChange, value }, { invalid }) =>
                                        <Autocomplete
                                            id="species_select"
                                            disableListWrap
                                            ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
                                            renderGroup={renderGroup}
                                            value={value}
                                            onChange={(event, value) => onChange(value)}
                                            onBlur={onBlur}
                                            getOptionLabel={(option) => option.label ?? ""}
                                            options={speciesCatalogueData.current.catalogueEntries}
                                            renderInput={(params) => <TextField {...params} variant="outlined" label={speciesCatalogueData.current.label} error={invalid} />}
                                            renderOption={(option) => <Typography noWrap>{option.label}</Typography>}
                                        />
                                }
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <p className={'validation_message'}>
                                {
                                    Validation
                                        .toErrorMessage('species', ValidationError.OBJECT_BASE, errors, t('required', { ns: 'validation' }))
                                    ||
                                    Validation
                                    .toErrorMessage('species', ValidationError.INVALID_VALUE, errors, t('required', { ns: 'validation' }))
                                }
                            </p>

                        </Grid>
                    </Grid>

                    {/* #### condition INPUT #### */}
                    <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                        <Grid item sm={6} xs={12}>
                            <Controller
                                defaultValue={{}}
                                control={control}
                                name={'condition'}
                                render={
                                    ({ onBlur, onChange, value }, { invalid }) =>
                                        <Autocomplete
                                            id="condition_select"
                                            disableListWrap
                                            ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
                                            renderGroup={renderGroup}
                                            value={value}
                                            onBlur={onBlur}
                                            getOptionLabel={(option) => option.name ?? ""}
                                            options={conditions}
                                            renderInput={(params) => <TextField {...params} variant="outlined" label={t('condition')} error={invalid} />}
                                            renderOption={(option) => <Typography noWrap>{option.name}</Typography>}
                                            onChange={(event, value) => onChange(value)}
                                            onInputChange={(event, value) => handleInputValueChange(value, CommonType.CONDITION)}
                                            noOptionsText={t('no_options_text', { ns: 'validation' })}
                                        />
                                }
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <p className={'validation_message'}>
                                {
                                    Validation
                                        .toErrorMessage('condition', ValidationError.OBJECT_BASE, errors, t('required', { ns: 'validation' }))
                                }
                            </p>

                        </Grid>
                    </Grid>

                    {/* #### target INPUT #### */}
                    <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                        <Grid item sm={6} xs={12}>
                            <Controller
                                defaultValue={{}}
                                control={control}
                                name={'target'}
                                render={
                                    ({ onBlur, onChange, value }, { invalid }) =>
                                        <Autocomplete
                                            id="target_select"
                                            disableListWrap
                                            ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
                                            renderGroup={renderGroup}
                                            value={value}
                                            onChange={(event, value) => onChange(value)}
                                            onBlur={onBlur}
                                            getOptionLabel={(option) => option.name ?? ""}
                                            options={targets}
                                            renderInput={(params) => <TextField {...params} variant="outlined" label={t('target')} error={invalid} />}
                                            renderOption={(option) => <Typography noWrap>{option.name}</Typography>}
                                            onInputChange={(event, value) => handleInputValueChange(value, CommonType.TARGET)}
                                            noOptionsText={t('no_options_text', { ns: 'validation' })}
                                        />
                                }
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                        </Grid>
                    </Grid>

                    {/* #### compound INPUT #### */}
                    <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                        <Grid item sm={6} xs={12}>
                            <Controller
                                defaultValue={{}}
                                control={control}
                                name={'compound'}
                                render={
                                    ({ onBlur, onChange, value }, { invalid }) =>

                                        <Autocomplete
                                            id="compound_select"
                                            disableListWrap
                                            ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
                                            renderGroup={renderGroup}
                                            value={value}
                                            onChange={(event, value) => onChange(value)}
                                            onBlur={onBlur}
                                            getOptionLabel={(option) => option.name ?? ""}
                                            options={compounds}
                                            renderInput={(params) => <TextField {...params} variant="outlined" label={t('compound')} error={invalid} />}
                                            renderOption={(option) => <Typography noWrap>{option.name}</Typography>}
                                            onInputChange={(event, value) => handleInputValueChange(value, CommonType.COMPOUND)}
                                            noOptionsText={t('no_options_text', { ns: 'validation' })}
                                        />
                                }
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                        </Grid>
                    </Grid>

                    {/* #### isTissueAvailable INPUT #### */}
                    <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                        <Grid item sm={6} xs={12}>
                            <Controller
                                defaultValue={false}
                                control={control}
                                name={'isTissueAvailable'}
                                render={
                                    ({ onBlur, onChange, value }) =>
                                        <FormControlLabel
                                            onBlur={onBlur}
                                            control={<Checkbox color="primary" checked={value} onChange={(event) => { onChange(event.target.checked) }} />}
                                            label={t('isTissueAvailable')}
                                            labelPlacement="end"
                                        />
                                }
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                        </Grid>
                    </Grid>
                    {
                        (studyFormValue.isTissueAvailable) &&
                        <>
                            {/* #### Link INPUT #### */}
                            <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                                <Grid item sm={6} xs={12}>
                                    <Controller
                                        defaultValue={''}
                                        control={control}
                                        name={'tissueType'}
                                        render={
                                            ({ onBlur, onChange, value }, { invalid }) =>
                                                <TextField
                                                    fullWidth={true}
                                                    error={invalid}
                                                    type={'text'}
                                                    value={value}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    label={t('tissueType')}
                                                    variant={'outlined'}
                                                />
                                        }
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <p className={'validation_message'}>
                                        {
                                            Validation
                                                .toErrorMessage('tissueType', ValidationError.STRING_EMPTY, errors, t('required', { ns: 'validation' })) ||
                                            Validation
                                                .toErrorMessage('tissueType', ValidationError.STRING_MAX, errors, t('maxLength', {
                                                    ns: 'validation',
                                                    len: 2000
                                                }))
                                        }
                                    </p>
                                </Grid>
                            </Grid>
                        </>
                    }

                    {/* #### Outcome Measure INPUT #### */}
                    <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                        <Grid item sm={6} xs={12}>
                            <Controller
                                defaultValue={''}
                                control={control}
                                name={'outcomeMeasure'}
                                render={
                                    ({ onBlur, onChange, value }, { invalid }) =>
                                        <TextField
                                            fullWidth={true}
                                            error={invalid}
                                            type={'text'}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            label={t('outcomeMeasure')}
                                            variant={'outlined'}
                                            helperText={t('outcomeMeasureHelperText')}
                                            FormHelperTextProps={{
                                                classes:{
                                                    root: styles.rootFormHelperText
                                                }
                                            }}
                                        />
                                }
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <p className={'validation_message'}>
                                {
                                    Validation
                                        .toErrorMessage('outcomeMeasure', ValidationError.STRING_MAX, errors, t('maxLength', {
                                            ns: 'validation',
                                            len: 80
                                        }))
                                }
                            </p>
                        </Grid>
                    </Grid>

                    <h1>3. {t('group_study_intervention')}</h1>

                    {/* #### testGroupNumberOfAnimals INPUT #### */}
                    <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                        <Grid item sm={6} xs={12}>
                            <Controller
                                defaultValue={''}
                                control={control}
                                name={'testGroupNumberOfAnimals'}
                                render={
                                    ({ onBlur, onChange, value }, { invalid }) =>
                                        <TextField
                                            fullWidth={true}
                                            error={invalid}
                                            type={'number'}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            label={t('interventionGroupNumberOfAnimals')}
                                            variant={'outlined'}
                                        />
                                }
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <p className={'validation_message'}>
                                {
                                    Validation
                                        .toErrorMessage('testGroupNumberOfAnimals', ValidationError.NUMBER_MIN, errors, t('largerThan', {
                                            ns: 'validation',
                                            min: 0
                                        })) ||
                                    Validation
                                        .toErrorMessage('testGroupNumberOfAnimals', ValidationError.NUMBER_MAX, errors, t('lowerThan', {
                                            ns: 'validation',
                                            max: 100000
                                        }))
                                }
                            </p>

                        </Grid>
                    </Grid>

                    {/* #### testGroupMeans INPUT #### */}
                    <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                        <Grid item sm={6} xs={12}>
                            <Controller
                                defaultValue={''}
                                control={control}
                                name={'testGroupMeans'}
                                render={
                                    ({ onBlur, onChange, value }, { invalid }) =>
                                        <TextField
                                            fullWidth={true}
                                            error={invalid}
                                            type={'number'}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            label={t('interventionGroupMeans')}
                                            variant={'outlined'}
                                        />
                                }
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <p className={'validation_message'}>
                                {
                                    Validation
                                        .toErrorMessage('testGroupMeans', ValidationError.NUMBER_MIN, errors, t('largerThan', {
                                            ns: 'validation',
                                            min: 0
                                        })) ||
                                    Validation
                                        .toErrorMessage('testGroupMeans', ValidationError.NUMBER_MAX, errors, t('lowerThan', {
                                            ns: 'validation',
                                            max: 100000
                                        }))
                                }
                            </p>

                        </Grid>
                    </Grid>

                    {/* #### testGroupStandardDeviation INPUT #### */}
                    <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                        <Grid item sm={6} xs={12}>
                            <Controller
                                defaultValue={''}
                                control={control}
                                name={'testGroupStandardDeviation'}
                                render={
                                    ({ onBlur, onChange, value }, { invalid }) =>
                                        <TextField
                                            fullWidth={true}
                                            error={invalid}
                                            type={'number'}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            label={t('interventionGroupStandardDeviation')}
                                            variant={'outlined'}
                                        />
                                }
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <p className={'validation_message'}>
                                {
                                    Validation
                                        .toErrorMessage('testGroupStandardDeviation', ValidationError.NUMBER_MIN, errors, t('largerThan', {
                                            ns: 'validation',
                                            min: 0
                                        })) ||
                                    Validation
                                        .toErrorMessage('testGroupStandardDeviation', ValidationError.NUMBER_MAX, errors, t('lowerThan', {
                                            ns: 'validation',
                                            max: 100000
                                        }))
                                }
                            </p>

                        </Grid>
                    </Grid>

                    <h1>4. {t('group_study_control')}</h1>

                    {/* #### controlGroupNumberOfAnimals INPUT #### */}
                    <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                        <Grid item sm={6} xs={12}>
                            <Controller
                                defaultValue={''}
                                control={control}
                                name={'controlGroupNumberOfAnimals'}
                                render={
                                    ({ onBlur, onChange, value }, { invalid }) =>
                                        <TextField
                                            fullWidth={true}
                                            error={invalid}
                                            type={'number'}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            label={t('controlGroupNumberOfAnimals')}
                                            variant={'outlined'}
                                        />
                                }
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <p className={'validation_message'}>
                                {
                                    Validation
                                        .toErrorMessage('controlGroupNumberOfAnimals', ValidationError.NUMBER_MIN, errors, t('largerThan', {
                                            ns: 'validation',
                                            min: 0
                                        })) ||
                                    Validation
                                        .toErrorMessage('controlGroupNumberOfAnimals', ValidationError.NUMBER_MAX, errors, t('lowerThan', {
                                            ns: 'validation',
                                            max: 100000
                                        }))
                                }
                            </p>

                        </Grid>
                    </Grid>

                    {/* #### controlGroupMeans INPUT #### */}
                    <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                        <Grid item sm={6} xs={12}>
                            <Controller
                                defaultValue={''}
                                control={control}
                                name={'controlGroupMeans'}
                                render={
                                    ({ onBlur, onChange, value }, { invalid }) =>
                                        <TextField
                                            fullWidth={true}
                                            error={invalid}
                                            type={'number'}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            label={t('controlGroupMeans')}
                                            variant={'outlined'}
                                        />
                                }
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <p className={'validation_message'}>
                                {
                                    Validation
                                        .toErrorMessage('controlGroupMeans', ValidationError.NUMBER_MIN, errors, t('largerThan', {
                                            ns: 'validation',
                                            min: 0
                                        })) ||
                                    Validation
                                        .toErrorMessage('controlGroupMeans', ValidationError.NUMBER_MAX, errors, t('lowerThan', {
                                            ns: 'validation',
                                            max: 100000
                                        }))
                                }
                            </p>

                        </Grid>
                    </Grid>

                    {/* #### controlGroupStandardDeviation INPUT #### */}
                    <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                        <Grid item sm={6} xs={12}>
                            <Controller
                                defaultValue={''}
                                control={control}
                                name={'controlGroupStandardDeviation'}
                                render={
                                    ({ onBlur, onChange, value}, { invalid }) =>
                                        <TextField
                                            fullWidth={true}
                                            error={invalid}
                                            type={'number'}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            label={t('controlGroupStandardDeviation')}
                                            variant={'outlined'}
                                        />
                                }
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <p className={'validation_message'}>
                                {
                                    Validation
                                        .toErrorMessage('controlGroupStandardDeviation', ValidationError.NUMBER_MIN, errors, t('largerThan', {
                                            ns: 'validation',
                                            min: 0
                                        })) ||
                                    Validation
                                        .toErrorMessage('controlGroupStandardDeviation', ValidationError.NUMBER_MAX, errors, t('lowerThan', {
                                            ns: 'validation',
                                            max: 100000
                                        }))
                                }
                            </p>

                        </Grid>
                    </Grid>

                    {/* #### Submit Button #### */}
                    <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                        <Grid item sm={6} xs={12}>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                size={'large'}
                                disabled={loggedInUser == null}
                                onClick={handleSubmit(handleSave, (e) => console.log(e))}
                            >
                                {study && study.studyId ? t('button_update') : t('button_submit')}
                            </Button>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                        </Grid>
                    </Grid>
                </Grid>
                <NotificationHandler onDismiss={onDismissNotification} notification={notifications[0]} />
            </Paper>
        </MuiPickersUtilsProvider>

    )
}



export default SubmitStudy;