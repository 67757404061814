import { AppNotification } from '../../core/models/notification';
import { Catalogue, CatalogueEntry } from '../../core/models/Common';

export const ConfigSliceName = 'configuration';

export interface ConfigState {
    isLoading: boolean;
    catalogues: Catalogue[];
    catalogue?: Catalogue;
    catalogueEntries: CatalogueEntry[];
    catalogueEntry?: CatalogueEntry;
    notifications: AppNotification[];
}

export enum CatalogueTypes {
    Outcome = 1,
    Species = 2,
    ProteinTargeting = 3,
}
