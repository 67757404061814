import { Theme } from '@material-ui/core';
import { CSSProperties } from 'react';
import { makeStyles } from '@material-ui/styles';

/**
 *    Note: Use this instead of makeStyles to obtain proper CSS autocompletion
 */

export const createStyleSheet:
    (param: (theme: Theme) => CSSProperties | { [key: string]: CSSProperties })
        => any = (param: (theme: Theme) => CSSProperties | { [key: string]: CSSProperties }) => makeStyles(param as any);

