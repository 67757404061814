import React, { Suspense, useEffect } from 'react';
//import logo from './logo.svg';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { ApplicationStore } from './core/state/store';
import { ThemeProvider } from '@material-ui/styles';
import { lightTheme } from './core/theme/lightTheme';
import LoadingComponent from './core/components/LoadingComponent';
import {BrowserRouter} from 'react-router-dom';
import NavBar from './core/components/NavBar';
import Footer from './core/components/Footer';
import { Settings as LuxonSettings } from 'luxon'
import { LocaleService } from './core/services/locale.service';
import { Environments } from './environments/environments';
import Overview from './common/components/overview';
import Studies from './study/components/Studies'
import Catalogue from './config/components/Catalogue'
import CatalogueDetails from './config/components/CatalogueDetails'
import CatalogueEntryPage from './config/components/CatalogueEntry'
import './App.css';
import { Redirect, Route, Switch } from 'react-router';
import { AuthenticationSelectors } from './authentication/state/authentication.selectors';
import '@fontsource/roboto';

import TermsAndConditions from './common/components/termsAndConditions'
import Disclaimer from './common/components/disclaimer'
import Impressum from './common/components/impressum'
import About from './common/components/about'
import SubmitStudy from './study/components/SubmitStudy'
import ContactPage from './common/components/contact';
import Login from "./authentication/components/Login";
import SignUp from "./authentication/components/SignUp";
import VerifyEmail from "./authentication/components/VerifyEmail";
import { AuthenticationActions } from "./authentication/state/authentication.slice";
import ForgotPassword from "./authentication/components/ForgotPassword";
import ResetPassword from "./authentication/components/ResetPassword";
import { AccountType, AuthStateEnum } from "./authentication/state/authentication.state";
import MyStudies from "./study/components/MyStudies";
import {ScrollToTop} from "./ScrollToTop";

function App() {
    useEffect(() => {
        document.title = Environments.title;
        LuxonSettings.defaultLocale = LocaleService.locale;
    }, [])

    return (
        <div className={'App'}>
            <Provider store={ApplicationStore}>
                <ThemeProvider theme={lightTheme}>
                    <Suspense fallback={<LoadingComponent />}>
                        <ApplicationRouter />
                    </Suspense>
                </ThemeProvider>
            </Provider>
        </div>
    );
}

const ApplicationRouter = () => {
    const dispatch = useDispatch();
    const authState = useSelector(AuthenticationSelectors.selectAuthState);
    const loggedInUser = useSelector(AuthenticationSelectors.selectLoggedInUser);

    useEffect(() => {
        dispatch(AuthenticationActions.refreshToken());
    }, [dispatch]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="page-container">
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <div className="content-wrap">
                    <ScrollToTop />
                    <NavBar loggedInUser={loggedInUser} />
                    <Switch>
                        <Route path={'/main'}>
                            <Overview/>
                        </Route>
                        <Route path={'/studies'}>
                            <Studies/>
                        </Route>
                        <Route exact path={'/submit'}>
                            <SubmitStudy />
                        </Route>
                        <Route path={'/about'}>
                            <About/>
                        </Route>
                        <Route path={'/contact'}>
                            <ContactPage/>
                        </Route>
                        <Route path={'/terms'}>
                            <TermsAndConditions/>
                        </Route>
                        <Route path={'/disclaimer'}>
                            <Disclaimer/>
                        </Route>
                        <Route path={'/impressum'}>
                            <Impressum/>
                        </Route>
                        {
                            authState !== AuthStateEnum.Checking && <>
                                {
                                    authState === AuthStateEnum.Authenticated ? <>
                                        <Switch>
                                            <Route path={'/forgot-password'}>
                                                <ForgotPassword/>
                                            </Route>
                                            <Route path={'/reset-password'}>
                                                <ResetPassword/>
                                            </Route>
                                            {loggedInUser?.accountType === AccountType.Admin &&
                                                <Route path={'/catalogue/:catid/entry/:entryid'}>
                                                    <CatalogueEntryPage />
                                                </Route>
                                            }
                                            {loggedInUser?.accountType === AccountType.Admin &&
                                                <Route path={'/catalogue/:catid/newentry'}>
                                                    <CatalogueEntryPage />
                                                </Route>}
                                            {loggedInUser?.accountType === AccountType.Admin &&
                                                <Route path={'/catalogue/:catid'}>
                                                    <CatalogueDetails />
                                                </Route>
                                            }
                                            {loggedInUser?.accountType === AccountType.Admin &&
                                                <Route path={'/catalogue'}>
                                                    <Catalogue />
                                                </Route>
                                            }
                                            <Route path={'/my-studies'}>
                                                <MyStudies />
                                            </Route>
                                            <Route path={'/submit/:studyId'}>
                                                <SubmitStudy />
                                            </Route>
                                            <Route path={'*'}>
                                                <Redirect to={'/main'}/>
                                            </Route>
                                        </Switch>
                                    </> :
                                        <Switch>
                                            <Route path={'/login'}>
                                                <Login/>
                                            </Route>
                                            <Route path={'/sign-up'}>
                                                <SignUp/>
                                            </Route>
                                            <Route path={'/verify-email'}>
                                                <VerifyEmail/>
                                            </Route>
                                            <Route path={'/forgot-password'}>
                                                <ForgotPassword/>
                                            </Route>
                                            <Route path={'/reset-password'}>
                                                <ResetPassword/>
                                            </Route>
                                            <Route path={'*'}>
                                                <Redirect to={'/main'}/>
                                            </Route>
                                        </Switch>
                                }
                            </>
                        }
                        <Route path={'*'}>
                            <Redirect to={'/main'}/>
                        </Route>
                    </Switch>
                </div>
                <Footer />
            </BrowserRouter>
        </div>
    )
}

export default App;
