import { ApplicationState } from './store';
import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state: ApplicationState) => state;

export const CoreSelectors = {
    selectLoading: createSelector(selectSelf, state =>
        state.study.isLoading
    ),
}
