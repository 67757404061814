import { createAsyncThunk } from '@reduxjs/toolkit';
import { CommonSliceName } from './common.state';
import { CommonApiService } from '../../core/services/common.api.service';
import { CatalogueEntry, Compound, Condition, Contact, Target } from '../../core/models/Common';

export const CommonThunks = {

    outcomes: createAsyncThunk<CatalogueEntry[], void>(`${CommonSliceName}/Outcomes`,
        async () => {
            const res = await CommonApiService.getOutcomes();
            return res.data.result;
        }),

    proteinTargetings: createAsyncThunk<CatalogueEntry[], void>(`${CommonSliceName}/ProteinTargetings`,
        async () => {
            const res = await CommonApiService.getProteinTargetings();
            return res.data.result;
        }),

    species: createAsyncThunk<CatalogueEntry[], void>(`${CommonSliceName}/Species`,
        async () => {
            const res = await CommonApiService.getSpecies();
            return res.data.result;
        }),

    condition: createAsyncThunk<Condition, number>(`${CommonSliceName}/Condition`,
        async (conditionId: number) => {
            const res = await CommonApiService.getCondition(conditionId);
            return res.data.result;
        }),

    conditions: createAsyncThunk<Condition[], void>(`${CommonSliceName}/Conditions`,
        async () => {
            const res = await CommonApiService.getConditions();
            return res.data.result;
        }),

    fetchConditions: createAsyncThunk<Condition[], string>(`${CommonSliceName}/FetchConditions`,
        async (payload) => {
            const res = await CommonApiService.fetchConditions(payload);
            return res.data.result;
        }),

    target: createAsyncThunk<Target, number>(`${CommonSliceName}/Target`,
        async (targetId: number) => {
            const res = await CommonApiService.getTarget(targetId);
            return res.data.result;
        }),

    targets: createAsyncThunk<Target[], void>(`${CommonSliceName}/Targets`,
        async () => {
            const res = await CommonApiService.getTargets();
            return res.data.result;
        }),

    fetchTargets: createAsyncThunk<Target[], string>(`${CommonSliceName}/FetchTargets`,
        async (payload) => {
            const res = await CommonApiService.fetchTargets(payload);
            return res.data.result;
        }),

    compound: createAsyncThunk<Compound, number>(`${CommonSliceName}/Compound`,
        async (compoundId: number) => {
            const res = await CommonApiService.getCompound(compoundId);
            return res.data.result;
        }),
    
    compounds: createAsyncThunk<Compound[], void>(`${CommonSliceName}/Compounds`,
        async () => {
            const res = await CommonApiService.getCompounds();
            return res.data.result;
        }),

    fetchCompounds: createAsyncThunk<Compound[], string>(`${CommonSliceName}/FetchCompounds`,
        async (payload) => {
            const res = await CommonApiService.fetchCompounds(payload);
            return res.data.result;
        }),

    contact: createAsyncThunk<void, Partial<Contact>>(`${CommonSliceName}/Contact`,
        async (payload, {rejectWithValue}) => {
            try{
                const res = await CommonApiService.contact(payload);
                return res.data.result;
            }
            catch {
                return rejectWithValue({});
            }
        })
}
