import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { CommonActions } from "../../common/state/common.slice";
import { CommonSelectors } from '../../common/state/common.selectors';
import { useAppDispatch } from "../../core/state/store";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Contact } from "../../core/models/Common";
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { Validation, ValidationError } from '../../core/utils/validation';
import NotificationHandler from '../../core/components/NotificationHandler';
import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
// @ts-ignore
import { loadCaptchaEnginge, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { NotificationEnum } from "../../core/models/notification.enum";
import { createStyleSheet } from "../../core/utils/theming";



const useStyles = createStyleSheet((theme) => ({
    mobileAppBody:{
        [theme.breakpoints.down('sm')]: {
            margin: 0
          },
    }
}));

const ContactPage = () => {
    const { t } = useTranslation(['common', 'validation', 'navbar']);
    const dispatch = useAppDispatch();
    const notifications = useSelector(CommonSelectors.selectNotifications);
    const [captcha, setCaptcha] = useState('');
    const styles = useStyles();


    useEffect(() => {
        return () => {
            dispatch(CommonActions.clearNotifications());
        }
    }, [dispatch]);

    const onDismissNotification = useCallback(() => {
        dispatch(CommonActions.dismissNotification());
    }, [dispatch]);

    const userSchema = Joi.object<Contact>({
        contactName: Joi.string().max(160).required(),
        contactEmail: Joi.string().max(160).required(),
        contactMessage: Joi.string().max(400).required(),
    })
    const { control, handleSubmit, errors, reset } = useForm<Contact>({
        defaultValues: {},
        resolver: joiResolver(userSchema)
    });

    const handleSave = useCallback((data: Contact) => {
        if (validateCaptcha(captcha)===true)
        {
            dispatch(CommonActions.contact(data)).unwrap()
            .then(() => {
                setCaptcha('');
                reset();
            })
        }
        else {
            dispatch(CommonActions.pushErrorNotification(NotificationEnum.ERROR_CAPTCHA));
        }
    }, [dispatch, reset, captcha]);

    useEffect(() => {
        loadCaptchaEnginge(6);
    }, [dispatch]);

    return (
        <Paper className={`App-body ${styles.mobileAppBody}`} variant={'outlined'} square>
            <Typography variant={'h3'} color={'primary'}>
                {t('label_contact', { ns: 'navbar' })}
            </Typography>

            <Grid container spacing={2} direction={'column'}>

                {/* #### contactName INPUT #### */}
                <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                    <Grid item sm={6} xs={12}>
                        <Controller
                            defaultValue={''}
                            control={control}
                            name={'contactName'}
                            render={
                                ({ onBlur, onChange, value }, { invalid }) =>
                                    <TextField
                                        fullWidth={true}
                                        error={invalid}
                                        type={'text'}
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        label={t('contactName')}
                                        variant={'outlined'}
                                    />
                            }
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <p className={'validation_message'}>
                            {Validation
                                .toErrorMessage('contactName', ValidationError.STRING_EMPTY, errors, t('required', { ns: 'validation' })) ||
                                Validation
                                    .toErrorMessage('contactName', ValidationError.STRING_MAX, errors, t('maxLength', {
                                        ns: 'validation',
                                        len: 160
                                    }))
                            }
                        </p>
                    </Grid>
                </Grid>

                {/* #### ContactEMail INPUT #### */}
                <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                    <Grid item sm={6} xs={12}>
                        <Controller
                            defaultValue={''}
                            control={control}
                            name={'contactEmail'}
                            render={
                                ({ onBlur, onChange, value }, { invalid }) =>
                                    <TextField
                                        fullWidth={true}
                                        error={invalid}
                                        type={'text'}
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        label={t('contactEmail')}
                                        variant={'outlined'}
                                    />
                            }
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <p className={'validation_message'}>
                            {
                                Validation
                                    .toErrorMessage('contactEmail', ValidationError.STRING_EMPTY, errors, t('required', { ns: 'validation' })) ||
                                Validation
                                    .toErrorMessage('contactEmail', ValidationError.STRING_EMAIL, errors, t('email', { ns: 'validation' })) ||
                                Validation
                                    .toErrorMessage('contactEmail', ValidationError.STRING_MAX, errors, t('maxLength', {
                                        ns: 'validation',
                                        len: 160
                                    }))
                            }
                        </p>

                    </Grid>
                </Grid>


                {/* #### contactMessage INPUT #### */}
                <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                    <Grid item sm={6} xs={12}>
                        <Controller
                            defaultValue={''}
                            control={control}
                            name={'contactMessage'}
                            render={
                                ({ onBlur, onChange, value }, { invalid }) =>
                                    <TextField
                                        fullWidth={true}
                                        error={invalid}
                                        type={'text'}
                                        value={value}
                                        multiline
                                        rows={4}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        label={t('contactMessage')}
                                        variant={'outlined'}
                                    />
                            }
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <p className={'validation_message'}>
                            {Validation
                                .toErrorMessage('contactMessage', ValidationError.STRING_EMPTY, errors, t('required', { ns: 'validation' })) ||
                                Validation
                                    .toErrorMessage('contactMessage', ValidationError.STRING_MAX, errors, t('maxLength', {
                                        ns: 'validation',
                                        len: 400
                                    }))
                            }
                        </p>
                    </Grid>
                </Grid>

                <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                    <Grid item sm={6} xs={12}>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                    </Grid>
                </Grid>

                <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                    <LoadCanvasTemplateNoReload />
                </Grid>

                

                {/* #### Captcha #### */}
                <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            fullWidth={true}
                            value={captcha}
                            onChange={(event) => setCaptcha(event.target.value)}
                            type={'text'}
                            label={t('captcha')}
                            variant={'outlined'}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                    </Grid>
                </Grid>

                {/* #### Submit Button #### */}
                <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                    <Grid item sm={6} xs={12}>
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            size={'large'}
                            onClick={handleSubmit(handleSave, (e) => console.log(e))}
                        >
                            {t('button_submit')}
                        </Button>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                    </Grid>
                </Grid>
            </Grid>
            <NotificationHandler onDismiss={onDismissNotification} notification={notifications[0]}/>
        </Paper>
    )
}



export default ContactPage;