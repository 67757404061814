import { CatalogueEntry, Compound, Condition, Target } from '../../core/models/Common';
import { AppNotification } from '../../core/models/notification';

export const CommonSliceName = 'common';

export interface CommonState {
    isLoading: boolean;
    outcome: CatalogueEntry[];
    proteinTargeting: CatalogueEntry[];
    species: CatalogueEntry[];
    condition: Condition | null;
    conditions: Condition[];
    target: Target | null;
    targets: Target[];
    compound: Compound | null;
    compounds: Compound[];
    notifications: AppNotification[];
}

export enum CommonType {
    CONDITION = 'condition',
    TARGET = 'target',
    COMPOUND = 'compound',
}
