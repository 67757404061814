import { createStyleSheet } from '../../core/utils/theming';
import {
    Assignment,
    Backup,
    CheckCircle,
    ContactPhone,
    Folder,
    Help,
    ImportContacts,
    LocalHospital,
    Publish,
    Storage,
    VpnKey
} from '@material-ui/icons';
import { Grid, Link, Paper } from "@material-ui/core";
import Carousel from 'react-material-ui-carousel';


const useStyles = createStyleSheet((theme) => ({
    mainGrid: {
        textAlign: 'center'
    },
    titleMain: {
        marginBottom: 20,
        borderBottomWidth: 4,
        borderBottomStyle: 'solid',
        borderBottomColor: '#0C3C60'
    },
    svgIcons: {
        fontSize: 50
    },
    legend: {
        textAlign: 'center'
    },
    image: {
        maxWidth: '100%',
        height: '46vh',
        objectFit: 'cover'
    },
    mobileAppBody: {
        [theme.breakpoints.down('sm')]: {
            margin: 0
        },
    }

}));

const Overview = () => {
    const styles = useStyles();

    return (
        <Paper className={`App-body ${styles.mobileAppBody}`} variant={'outlined'} square>

            <Grid container spacing={2} direction={'column'} alignItems='center' className={styles.mainGrid}>
                <Grid item style={{width: '100%'}}>
                    <Carousel>
                        <Grid container direction={'column'}>
                            <img src="img/F1.jpg" alt="" className={styles.image}/>
                            <p className={styles.legend}>An initiative and service from and for pre-clinical
                                scientists</p>
                        </Grid>
                        <Grid container direction={'column'}>
                            <img src="img/F2.jpg" alt="" className={styles.image}/>
                            <p className={styles.legend}>Inspired by ClinicalTrials.gov</p>
                        </Grid>
                        <Grid container direction={'column'}>
                            <img src="img/F3.jpg" alt="" className={styles.image}/>
                            <p className={styles.legend}>Inspired by ClinicalTrials.gov</p>
                        </Grid>
                        <Grid container direction={'column'}>
                            <img src="img/F4.jpg" alt="" className={styles.image}/>
                            <p className={styles.legend}>Inspired by ClinicalTrials.gov</p>
                        </Grid>
                        <Grid container direction={'column'}>
                            <img src="img/F5.jpg" alt="" className={styles.image}/>
                            <p className={styles.legend}>Inspired by ClinicalTrials.gov</p>
                        </Grid>
                        <Grid container direction={'column'}>
                            <img src="img/F6.jpg" alt="" className={styles.image}/>
                            <p className={styles.legend}>Inspired by ClinicalTrials.gov</p>
                        </Grid>
                    </Carousel>
                </Grid>
                <Grid item>
                    <h1 className={styles.titleMain}>
                        For the press patients and the general public
                    </h1>
                </Grid>
                <Grid item container spacing={2} direction={'row'} justifyContent='space-evenly'>
                    <Grid xs={12} sm={6} md={4} lg={3} item container spacing={2} direction={'column'}
                          alignItems='center' className={styles.itembox}>
                        <Grid item>
                            <Help color={'primary'} className={styles.svgIcons}/>
                        </Grid>
                        <Grid item>
                            <h2>
                                Why this site?
                            </h2>
                        </Grid>
                        <Grid item>
                            <p>
                                About 60% of all biomedical literature, including animal experiments, is not
                                reproducible, yet pre-clinical basic research represents a scientific basis to identify
                                disease mechanisms, new drugs and diagnostics. Moreover, negative drug validation data
                                are often not published, i.e. there is a bias to publish accidentally positive data.
                                This fact together with irreproducibility and poor analysis impact later success to
                                achieve patient benefit. Our way forward is to focus on animal experiments, enable
                                sharing of also negative data and collaboration on pooled data, samples and reagents.
                                For the moment, this site focuses only on those animal experiments aimed at identifying
                                disease mechanisms, new disease diagnostics and new therapies.
                            </p>
                        </Grid>

                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={3} item container spacing={2} direction={'column'}
                          alignItems='center' className={styles.itembox}>
                        <Grid item>
                            <CheckCircle color={'primary'} className={styles.svgIcons}/>
                        </Grid>
                        <Grid item>
                            <h2>
                                Success stories
                            </h2>
                        </Grid>
                        <Grid item>
                            <p>
                                Several landmark studies have shown the utility of re-analysing pre-existing studies and
                                small pilot data to identify positive publication bias and to validate a previous claim
                                on disease mechanisms, diagnostics or therapeutics. The methods are quite similar to
                                what is done in re-analysing clinical patient data, but so far is the exception in
                                pre-clinical research.
                            </p>
                        </Grid>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={3} item container spacing={2} direction={'column'}
                          alignItems='center' className={styles.itembox}>
                        <Grid item>
                            <Assignment color={'primary'} className={styles.svgIcons}/>
                        </Grid>
                        <Grid item>
                            <h2>
                                Learn about pre-clinical research
                            </h2>
                        </Grid>
                        <Grid item>
                            <p>
                                Pre-clinical research, in particular in animal models of disease, has two functions,
                                i.e., to first generate new diagnostic or therapeutic hypotheses (exploratory trials)
                                and to then confirm these hypotheses and convert them into reproducible knowledge
                                (confirmatory trials). Such pre-clinical evidence can then be the basis for clinical
                                studies and drug development.
                            </p>
                        </Grid>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={3} item container spacing={2} direction={'column'}
                          alignItems='center' className={styles.itembox}>
                        <Grid item>
                            <LocalHospital color={'primary'} className={styles.svgIcons}/>
                        </Grid>
                        <Grid item>
                            <h2>
                                The 3Rs
                            </h2>
                        </Grid>
                        <Grid item>
                            <p>
                                The Three Rs (3Rs) in animal research are ethical guidelines to ensure maximal respect
                                while performing animal experimentation. Specifically, the goal of the 3R Principle is
                                to avoid animal experiments when other options are possible (Replacement), to limit the
                                number of animals (Reduction) and their suffering (Refinement) in tests to an absolute
                                minimum using methods that minimize any potential pain, suffering or distress.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <h1 className={styles.titleMain}>
                        For Preclinical Researchers
                    </h1>
                </Grid>
                <Grid item container spacing={2} direction={'row'} justifyContent='space-evenly'>
                    <Grid xs={12} sm={6} md={4} lg={3} item container spacing={2} direction={'column'}
                          alignItems='center'>
                        <Grid item>
                            <Folder color={'primary'} className={styles.svgIcons}/>
                        </Grid>
                        <Grid item>
                            <h2>
                                What data is relevant?
                            </h2>
                        </Grid>
                        <Grid item>
                            <p>
                                In-vivo animal data in all animals used for animal experimentation on disease targets,
                                drugs or new mechanism-based diagnostics in validated animal models of human diseases.
                                In addition to published data, in particular negative pilot data are of interest, which
                                would otherwise not have been published.
                            </p>
                        </Grid>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={3} item container spacing={2} direction={'column'}
                          alignItems='center'>
                        <Grid item>
                            <Backup color={'primary'} className={styles.svgIcons}/>
                        </Grid>
                        <Grid item>
                            <h2>
                                How to submit data?
                            </h2>
                        </Grid>
                        <Grid item>
                            <p>
                                Data are submitted as a study, irrespective of how few animals were tested. Key elements
                                include species, modelling which human disease (use ICD), target gene/protein (e.g. for
                                knock-outs or knock-ins) or drug class (based on protein target). All data will receive
                                a submission ID for use with journals and funding agencies to document timely data
                                storage.
                            </p>
                        </Grid>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={3} item container spacing={2} direction={'column'}
                          alignItems='center'>
                        <Grid item>
                            <Backup color={'primary'} className={styles.svgIcons}/>
                        </Grid>
                        <Grid item>
                            <h2>
                                How to submit biobank sample?
                            </h2>
                        </Grid>
                        <Grid item>
                            <p>
                                When submitting data, you can also indicate whether biobanked samples are available.
                                This allows other researchers to collaborate with you, e.g., to rapidly test a biomarker
                                in a disease model without the lengthy process of establishing this disease model
                                themselves and wasting animals.
                            </p>
                        </Grid>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={3} item container spacing={2} direction={'column'}
                          alignItems='center'>
                        <Grid item>
                            <Storage color={'primary'} className={styles.svgIcons}/>
                        </Grid>
                        <Grid item>
                            <h2>
                                Procedures
                            </h2>
                        </Grid>
                        <Grid item>
                            <p>
                                An unlimited hold may be put on deposited data if IP is to be secured.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <h1 className={styles.titleMain}>
                        For Publishers and Funders
                    </h1>
                </Grid>
                <Grid item container spacing={2} direction={'row'} justifyContent='space-evenly'>
                    <Grid xs={12} sm={6} md={4} lg={3} item container spacing={2} direction={'column'}
                          alignItems='center'>
                        <Grid item>
                            <Publish color={'primary'} className={styles.svgIcons}/>
                        </Grid>
                        <Grid item>
                            <h2>
                                Why publish and fund only registered studies?
                            </h2>
                        </Grid>
                        <Grid item>
                            <p>
                                Publishers and funders have the greatest responsibility in preventing publication bias.
                                By requiring that any study that is to be published in a journal or funded by a research
                                grant had been registered before in pre-clinicaltrials.org they can indirectly ensure
                                that also negative data will later be added and accessible.
                            </p>
                        </Grid>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={3} item container spacing={2} direction={'column'}
                          alignItems='center'>
                        <Grid item>
                            <ContactPhone color={'primary'} className={styles.svgIcons}/>
                        </Grid>
                        <Grid item>
                            <h2>
                                How to engage
                            </h2>
                        </Grid>
                        <Grid item>
                            <p>
                                Editors can engage with us on their preferred procedures on trial registration and
                                documentation. Currently, we provide one unique study identifier for each deposited
                                independent dataset.
                            </p>
                        </Grid>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={3} item container spacing={2} direction={'column'}
                          alignItems='center'>
                        <Grid item>
                            <VpnKey color={'primary'} className={styles.svgIcons}/>
                        </Grid>
                        <Grid item>
                            <h2>
                                About the results database?
                            </h2>
                        </Grid>
                        <Grid item>
                            <p>
                                The results database serves as a central place to store and match pre-clinical
                                experiments as well as biobank repositories.
                            </p>
                        </Grid>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={3} item container spacing={2} direction={'column'}
                          alignItems='center'>
                        <Grid item>
                            <ImportContacts color={'primary'} className={styles.svgIcons}/>
                        </Grid>
                        <Grid item>
                            <h2>
                                Open access
                            </h2>
                        </Grid>
                        <Grid item>
                            <p>
                                We consider that providing free online access to research data is the most effective way
                                of ensuring that the results of sacrificing animals and public funding can be accessed,
                                read and used as the basis for further research and clinical translation. In that
                                respect we fully support the guidelines of the European Research Council:
                            </p>
                            <Link
                                href="https://erc.europa.eu/sites/default/files/document/file/ERC_Open_Access_Guidelines-revised_2013.pdf"
                                color="inherit">
                                {'Click here'}
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Paper>
    )
}


export default Overview;
