import { Grid, Paper, Typography } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import { createStyleSheet } from "../../core/utils/theming";

const useStyles = createStyleSheet((theme) => ({
    mobileAppBody:{
        [theme.breakpoints.down('sm')]: {
            margin: 0
          },
    }
}));

const TermsAndConditions = () => {
    const { t } = useTranslation('navbar');
    const styles = useStyles();


    return (
        <Paper className={`App-body ${styles.mobileAppBody}`} variant={'outlined'} square>

            <Grid container spacing={2} direction={'column'} style={{ height: '100%' }}>
                <Grid item>
                    <Typography variant={'h3'} color={'primary'}>
                        {t('label_terms')}
                    </Typography>

                    <h1>Introduction</h1>

                    <p>
                        The following Terms and Conditions apply for use of Pre-ClinicalTrials.org data. Downloading Pre-ClinicalTrials.org data indicates your acceptance of the following Terms and Conditions. These Terms and Conditions apply to all data obtained from Pre-ClinicalTrials.org, independent of format and method of acquisition.
                    </p>

                    <h1>Purpose of Pre-ClinicalTrials.org Database</h1>

                    <p>
                        The Pre-ClinicalTrials.org Consortium has developed Pre-ClinicalTrials.org based on the clinical pendant, ClinicalTrials.gov, to provide researchers and members of the public current information about pre-clinical research studies. Pre-ClinicalTrials.org contains summary information about pre-clinical studies being conducted throughout Europe and in many countries throughout the world. These data are provided to the Pre-ClinicalTrials.org Consortium by registered researchers to support excellence and transparancy in biomedical science. You should check Pre-ClinicalTrials.org frequently for updated information.
                    </p>

                    <h1>Availability of Data</h1>

                    <p>
                        Pre-ClinicalTrials.org data are available to all registered requesters, both within and outside Europe, at no charge.
                    </p>

                    <h1>Use of Pre-ClinicalTrials.org Data</h1>

                    <p>
                        Pre-ClinicalTrials.org Consortium makes nowarranties, expressed or implied, with respect to data contained in the database, and, furthermore, assumes no liability for any party's use, or the results of such use, of any part of the database.
                    </p>

                    <ul>
                        <li>Attribute the source of the data as Pre-ClinicalTrials.org</li>
                        <li>Update the data such that they are current at all times</li>
                        <li>Clearly display the date on which the data were processed by Pre-ClinicalTrials.org</li>
                        <li>State any modifications made to the content of the data, along with a complete description of the modifications</li>
                    </ul>

                    <p>
                        You shall not assert any proprietary rights to any portion of the database, or represent the database or any part thereof to anyone as other than a Pre-ClinicalTrials.org database.
                    </p>
                    <p>
                        You shall not use any email addresses extracted from our database for marketing, promotional or any other purposes.
                    </p>
                    <p>
                        The Pre-ClinicalTrials.org data carry an international copyright outside Europe. Some Pre-ClinicalTrials.org data may be subject to the copyright of third parties; you should consult these entities for any additional terms of use.
                    </p>
                    <p>
                        These terms and conditions are in effect as long as the user retains any of the data obtained from Pre-ClinicalTrials.org.
                    </p>

                </Grid>
            </Grid>
        </Paper>
    );
}



export default TermsAndConditions;