import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { createStyleSheet } from "../../core/utils/theming";
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {useCallback, useEffect} from 'react';
import {useAppDispatch} from '../../core/state/store';
import {Refresh} from '@material-ui/icons';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import {useSelector} from 'react-redux';
import {ConfigSelectors} from '../state/config.selectors';
import {ConfigActions} from '../state/config.slice';
import {useHistory} from 'react-router-dom';

const useStyles = createStyleSheet(() => ({}));

const Catalogue = () => {
    const styles = useStyles();
    const {t} = useTranslation('config');
    const {handleSubmit} = useForm();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const catalogues = useSelector(ConfigSelectors.selectCatalogues);

    useEffect(() => {
        dispatch(ConfigActions.getCatalogues());
    }, [dispatch]);

    const onRefreshCatalogues = useCallback(() => {
        dispatch(ConfigActions.getCatalogues());
    }, [dispatch])

    const gridActionCellRenderer = (props: any) => {
        return (
            <span>
                <button onClick={() => history.push(`/catalogue/${props.value}`)}>Edit</button>
            </span>
        );
    };

    return (
        <Paper className={`App-body ${styles.mobileAppBody}`} variant={'outlined'} square>

            <Grid container spacing={2} direction={'column'} style={{ height: '100%' }}>
                <Grid item>
                    <Typography variant={'h3'} color={'primary'}>
                        {t('catalogue_title')}
                    </Typography>
                </Grid>
                <Grid item container direction={'row'} spacing={2} alignItems={'center'}>
                    <Grid item xs>
                    </Grid>
                    <Grid item>
                        <Button
                            size={'large'}
                            variant={'contained'}
                            color={'primary'}
                            onClick={handleSubmit(onRefreshCatalogues)}
                            startIcon={<Refresh />}
                        >{t('button_refresh')}</Button>
                    </Grid>
                </Grid>
                <Grid xs item className="ag-theme-alpine" style={{ width: '100%' }}>
                    <AgGridReact rowData={catalogues}
                                 frameworkComponents={{btnCellRenderer: gridActionCellRenderer,}}
                    >
                        <AgGridColumn headerName={t('actions')} field="catalogueId" headerTooltip={t('actions')} resizable cellRenderer='btnCellRenderer' />
                        <AgGridColumn headerName={t('label')} field="label" filter="agTextColumnFilter" sortable headerTooltip={t('label')} resizable tooltipField="label" />
                        <AgGridColumn headerName={t('isRequired')} field="isRequired" filter="agTextColumnFilter" sortable headerTooltip={t('isRequired')} resizable tooltipField="isRequired" />
                    </AgGridReact>
                </Grid>
            </Grid>
        </Paper>
    );
}



export default Catalogue;