import {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AuthenticationActions} from "../state/authentication.slice";
import NotificationHandler from "../../core/components/NotificationHandler";
import {AuthenticationSelectors} from "../state/authentication.selectors";
import {Button, Card, CardContent, Grid, TextField, Typography} from "@material-ui/core";
import {Controller, useForm} from "react-hook-form";
import {ExitToApp} from "@material-ui/icons";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {authenticationComponentStyles} from "../../core/styles/authenticationComponentStyles";

interface ForgotPasswordFormProps {
    email: string;
}

const ForgotPassword = () => {
    const styles = authenticationComponentStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const {t} = useTranslation(['authentication', 'validation']);
    const {control, handleSubmit, errors} = useForm();
    const isLoading = useSelector(AuthenticationSelectors.selectLoading);
    const notifications = useSelector(AuthenticationSelectors.selectNotifications);
    const showResetPasswordComponent = useSelector(AuthenticationSelectors.selectShowResetPasswordComponent);

    useEffect(() => {
        if (showResetPasswordComponent) {
            history.push('/reset-password');
        }
    }, [showResetPasswordComponent, history]);

    const onDismissNotification = useCallback(() => {
        dispatch(AuthenticationActions.dismissNotification());
    }, [dispatch]);

    const onSubmit = ({email}: ForgotPasswordFormProps) => {
        dispatch(AuthenticationActions.forgotPassword({
            email: email
        }));
    }

    return (
        <div className={styles.root}>
            <div className={styles.cardContainer}>
                <Card className={styles.card}>
                    <CardContent className={styles.cardContent}>
                        <div className={styles.cardHeader}>
                            <Typography variant={'h3'} color={'primary'} style={{ fontWeight: 'bold' }}>RepoTrial</Typography>
                        </div>
                        <Card elevation={12} className={styles.cardBody}>
                            <CardContent style={{height: '100%'}}>
                                <form onSubmit={handleSubmit(onSubmit)} style={{height: '100%'}}>
                                    <Grid
                                        container
                                        direction={'column'}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                        style={{height: '100%'}}
                                    >
                                        <Grid item>
                                            <Typography variant={'h4'}
                                                        color={'primary'}>{t('label_forgot_password')}</Typography>
                                        </Grid>

                                        <Grid item>
                                            <Typography variant={'h6'} color={'primary'} align={'center'}>
                                                {t('message_forgot_password')}
                                            </Typography>
                                        </Grid>

                                        <Grid item container direction={'column'} spacing={errors.username ? 0 : 2} alignItems={'center'}>
                                            <Grid item>
                                                <Controller
                                                    defaultValue={''}
                                                    control={control}
                                                    rules={{required: true}}
                                                    name={'email'}
                                                    render={
                                                        ({onBlur, onChange, value}, {invalid}) => <TextField
                                                            type={'text'}
                                                            className={styles.cardTextInput}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            error={invalid}
                                                            label={t('label_email')}
                                                            variant="outlined"
                                                            helperText={invalid ? t('required', {ns: 'validation'}) : undefined}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                color={'primary'}
                                                type={'submit'}
                                                variant={'contained'}
                                                startIcon={<ExitToApp/>}
                                                disabled={isLoading}
                                                onSubmit={handleSubmit(onSubmit)}
                                            >
                                                {t('button_change_password')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </CardContent>
                </Card>
            </div>
            <NotificationHandler onDismiss={onDismissNotification} notification={notifications[0]}/>
        </div>
    );
}

export default ForgotPassword;