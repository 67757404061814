import { ApplicationState } from '../../core/state/store';
import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state: ApplicationState) => state.config;

export const ConfigSelectors = {
    selectCatalogues: createSelector(selectSelf, state => state.catalogues),
    selectCatalogue: createSelector(selectSelf, state => state.catalogue),
    selectCatalogueEntries: createSelector(selectSelf, state => state.catalogueEntries),
    selectCatalogueEntry: createSelector(selectSelf, state => state.catalogueEntry),
    selectIsLoading: createSelector(selectSelf, state => state.isLoading),
    selectNotifications: createSelector(selectSelf, state => state.notifications)
}
