export interface Catalogue {
    catalogueId: number | null;
    label: string | null;
    isRequired: boolean | null;
    catalogueEntries: CatalogueEntry[] | [];
}

export const emptyCatalogue: Catalogue = {
    catalogueId: null,
    label: null,
    isRequired: null,
    catalogueEntries: [],
}

export interface CatalogueEntry {
    catalogueEntryId: number;
    catalogueId: number;
    label: string;
    position: string;
    isDefault: boolean;
    isActive: boolean;
}

export interface Condition {
    conditionId: number;
    name: string;
    description: string;
    externalId: string;
}

export interface Compound {
    compoundId: number;
    name: string;
    description: string;
    externalId: string;
}

export interface Target {
    targetId: number;
    name: string;
    description: string;
    externalId: string;
}

export interface Contact {
    contactName: string;
    contactEmail: string;
    contactMessage: string;
}