import { ApiResponse } from '../models/ApiResponse';
import { AxiosInstance } from './axios';
import {CreateStudy, Study, UpdateStudyWebRequestBody} from '../models/Study';

const PATH = 'Study'

export const StudyApiService = {
    fetch: (keywords: string): ApiResponse<Study[]> => {
        return AxiosInstance.post(`${PATH}/Fetch`, {keywords});
    },

    createStudy: (data: Partial<CreateStudy>) => {
        return AxiosInstance.post(`${PATH}`, data);
    },

    updateStudy: (payload: UpdateStudyWebRequestBody) => {
        return AxiosInstance.patch(`${PATH}/${payload.studyId}`, payload.study);
    },

    fetchMyStudies: (keywords: string) => {
        return AxiosInstance.post(`${PATH}/MyStudies`, {keywords});
    },

    getStudy: (studyId: string) => {
        return AxiosInstance.get(`${PATH}/MyStudies/${studyId}`);
    },

}
