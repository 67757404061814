import {CreateStudy, Study} from '../../core/models/Study';
import { AppNotification } from '../../core/models/notification';

export const StudySliceName = 'studies';

export interface StudyState {
    keywords: string;
    isLoading: boolean;
    studies: Study[];
    myStudies: Study[];
    study?: null | CreateStudy;
    notifications: AppNotification[];
}
