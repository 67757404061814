import { ApiResponse } from '../models/ApiResponse';
import { AxiosInstance } from './axios';
import { Catalogue, CatalogueEntry } from '../models/Common';

const PATH = 'Configuration'

export const ConfigApiService = {
    getCatalogues: (): ApiResponse<Catalogue[]> => {
        return AxiosInstance.get(`${PATH}/Catalogues`);
    },
    getCatalogue: (id: string): ApiResponse<Catalogue> => {
        return AxiosInstance.get(`${PATH}/Catalogues/${id}`);
    },
    updateCatalogue: (id: number, data: Partial<Catalogue>) => {
        return AxiosInstance.patch(`${PATH}/Catalogues/${id}`, data);
    },
    getCatalogueEntries: (id: string): ApiResponse<CatalogueEntry[]> => {
        return AxiosInstance.get(`${PATH}/Catalogues/${id}/Entries`);
    },
    getCatalogueEntry: (id: string): ApiResponse<CatalogueEntry> => {
        return AxiosInstance.get(`${PATH}/CatalogueEntries/${id}`);
    },
    createCatalogueEntry: (data: Partial<CatalogueEntry>) => {
        return AxiosInstance.post(`${PATH}/CatalogueEntries`, data);
    },
    updateCatalogueEntry: (id: number, data: Partial<CatalogueEntry>) => {
        return AxiosInstance.patch(`${PATH}/CatalogueEntries/${id}`, data);
    },
    deleteCatalogueEntry: (id: number) => {
        return AxiosInstance.delete(`${PATH}/CatalogueEntries/${id}`);
    },
}
